<template>

    <h2 class="font-medium text-lg md:text-2xl mt-4 md:mt-8 mx-2 md:mx-10">
     Messages
    </h2>

    <!-- Filters -->
    <div class="flex w-full px-2 md:px-10 mt-4 md:mt-6 gap-x-4">
        <!-- Type filter -->
        <div v-if="messageTypeSelectOptions && messageTypeSelectOptions.length>0" class="border w-[150px] rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
              <div class="relative">
                <select id="messageTypeSelect" ref="messageTypeSelect" multiple data-hs-select='{
                "placeholder": "Type",
                "toggleTag": "<button type=\"button\"></button>",
                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                    <option v-for="type in messageTypeSelectOptions" :key="type" :value="type">
                    {{ type}}
                    </option>
                </select>

                <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path d="m7 15 5 5 5-5" />
                    <path d="m7 9 5-5 5 5" />
                    </svg>
                </div>
              </div>
            <!-- End Select -->
        </div>

        <!-- Sorting Filter -->
        <div class="border w-[150px] rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">      
                <div class="relative">
                    <select v-model="selectedSort" id="sortingSelect" ref="sortingSelect" data-hs-select='{
                        "placeholder": "Sort",
                        "toggleTag": "<button type=\"button\"></button>",
                        "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                        "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                        "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                        "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                        }' class="hidden">
                        <option value="desc">Sort: New to old</option>
                        <option value="asc">Sort: Old to New</option>
                    </select>

                    <div class="absolute top-1/2 end-3 -translate-y-1/2">
                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                        </svg>
                    </div>
                </div>
                <!-- End Select -->
        </div>
    </div>

     <!-- Messages Table/Messages List for Mobile-->
    <div class="md:hidden mx-2 md:mx-10 mt-6 rounded max-h-[76%] overflow-auto md:overflow-visible md:h-auto md:max-h-auto">
  
       <!-- Message cards -->
      <div v-for="(message, index) in messageList" @click="handleMessageClick(message, index)" :key="message.name" class="flex flex-col border rounded-md text-sm px-3 cursor-pointer md:justify-between mb-2"
      :class="[moment().diff(moment(message.creation_time), 'hours') < 24 ? 'bg-[#FFFBE8]' : '']">
        
        <!-- Type and Date/time -->
        <div class="flex w-full justify-between mt-3">
          <div v-if="message.creation_time" class="text-center text-slate-500 font-medium">
            {{ message.message_type }}
          </div>
          <div v-if="message.creation_time" class="text-slate-500 font-medium">
            {{ moment(message.creation_time).format('DD.MM.YY') }}
          </div>
        </div>

        <!-- Cohorts -->
        <div v-if="associatedCohortIds && associatedCohortIds.length>1" class="w-full flex mt-1"> 
          <div class="text-slate-500 font-medium mr-1">For:</div>
          <div class="">
            {{ message.cohorts && message.cohorts.length > 0 ? message.cohorts.filter(cohort => associatedCohortIds.includes(cohort.cohort_id)).map(cohort => cohort.cohort_name).join(', ') 
            : 'N/A' }}
          </div>
        </div> 

        <!-- Message content -->
        <div class="py-2 md:text-center truncate">{{ message.message_content }}</div>
      
      </div>
    </div>

      <!-- Messages Table/Messages List for Desktop -->
    <div class="hidden md:block border mx-2 md:mx-10 mt-6 rounded max-h-[76%] overflow-auto md:overflow-visible md:h-auto md:max-h-auto">
        <!-- Header Row -->
        <div class="flex justify-between font-medium">
          <div class="w-1/3 py-1 pl-3">Cohort</div>
          <div class="w-1/3 py-1 text-center">Messages</div>
          <div class="w-1/3 py-1 text-center">Date</div>
        </div>

        <!-- Data Rows -->
        <div 
          v-for="(message, index) in messageList" @click="handleMessageClick(message, index)" 
          :key="message.name"
          :class="{'bg-[#FFFBE8]': index % 2 === 0}"
          class="flex flex-row border-t text-sm cursor-pointer md:justify-between">
          <div class="w-1/3 md:w-[32%] pl-3 py-2 truncate">
            {{ message.cohorts && message.cohorts.length > 0 
              ? message.cohorts
                  .filter(cohort => associatedCohortIds.includes(cohort.cohort_id))
                  .map(cohort => cohort.cohort_name)
                  .join(', ') 
              : 'N/A' }}
          </div>
          <div class="w-1/3 md:w-[32%] py-2 truncate md:text-center">{{ message.message_content }}</div>
          <div v-if="message.creation_time" class="w-1/3 md:w-[32%] text-center pl-3 pr-1 py-2 text-slate-500 font-medium">
            {{ moment(message.creation_time).format('DD.MM.YY') }}
          </div>
          <div v-else class="w-1/3 md:w-[32%] text-center pl-3 pr-1 py-2 text-slate-500 font-medium"></div>
        </div>
    </div>


  
    <!-- Pagination Controls -->
    <div class="w-full flex justify-end mr-2 md:mr-0 mt-2">
      <div v-if="messageList.length > 0" class="justify-between flex w-auto px-3 md:px-0 mt-2 gap-x-6 md:mr-10">
        <button @click="goToPreviousPage" :disabled="currentPage === 1" class="text-xl font-semibold">&larr;</button>
        <span class="mt-0.5">Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="goToNextPage" :disabled="currentPage === totalPages" class="text-xl font-semibold">&rarr;</button>
      </div>
    </div>
  
    <!-- Spinner logo -->
    <div v-if="isLoading" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50">
      <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
        <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
     
    
      <viewMessageDetailsModal v-if="selectedMessage && messageList.length > 0"
        :message="selectedMessage"
        :messageList="messageList"
        :messageIndex="selectedMessageIndex"
        @update:message="selectedMessage = $event"
        @update:messageIndex="selectedMessageIndex = $event"
        @nextPage="loadNextPageForViewDetailsModal"
        @previousPage="loadPreviousPageForViewDetailsModal"/>

  </template>
  
  <script setup>
  import { ref, onMounted, inject } from 'vue';
  import moment from 'moment';
  import viewMessageDetailsModal from '../components/messaging/parentMessaging/viewMessageDetailsModal.vue';
  
  // Inject the $call method
  const $call = inject('$call');
  
  const messageList = ref([]); // Store fetched messages
  const currentPage = ref(1);
  const pageSize = ref(15);
  const isLoading = ref(true);
  const totalPages = ref(0); // Total pages from API
  
  const selectedMessage = ref(null);
  const selectedSort = ref('desc'); // Default to descending order
  const messageTypeSelectOptions = ref([])
  const messageTypeSelect = ref(null);
  const associatedCohortIds = ref(null)
  
  
  onMounted(() => {
    fetchMessagesList();
    
  });

  
  const selectedMessageIndex = ref(null);

  


  const handleMessageClick = (message, index) => {
    selectedMessage.value = message;
    selectedMessageIndex.value = index;
  };

  
  const fetchMessagesList = async () => {
    console.log("fetch message list called")
    try {
      isLoading.value = true;
      const response = await $call('curriculum_planner.curriculum_planner.doctype.admin_messages.api.fetch_cohort_specific_messages_for_parent', {
        page_number: currentPage.value,
        page_size: pageSize.value,
        sort_order: selectedSort.value
      });

      console.log("TT:this is messages list fetch response",response)
  
      // Populate messageList and pagination data
      messageList.value = response.messages || [];
      totalPages.value = response.pagination.total_pages || 0;
      messageTypeSelectOptions.value = response.message_type_options|| [];
      associatedCohortIds.value = response.associated_cohorts.map(cohort => cohort.cohort);
      isLoading.value = false; 
    } catch (error) {
      console.error('Error fetching messages list:', error);
      isLoading.value = false;
    }

    setTimeout(() => {
        window.HSStaticMethods.autoInit();
          
          // Initialize the messageTypeSelect element for sorting filter
          const messageTypeSelectElement = window.HSSelect.getInstance('messageTypeSelect');
            if (messageTypeSelectElement) {
                messageTypeSelectElement.on('change', (value) => {
                console.log('Select messageType filter value changed:', value);
                messageTypeSelect.value = value
                fetchMessagesList();
            });
            } else {
                console.log('instance not found');
            }


            // Initialize the select element for sorting filter
            const sortSelectElement = window.HSSelect.getInstance('#sortingSelect');
            if (sortSelectElement) {
                sortSelectElement.on('change', (value) => {
                console.log('Select sorting filter value changed:', value);
                selectedSort.value = value
                fetchMessagesList();
            });
            } else {
                console.log('instance not found');
            }

      }, 100);
  };
  
  
  // Methods to navigate between pages
  const goToNextPage = () => {
    if (currentPage.value < totalPages.value) {
      currentPage.value++;
      fetchMessagesList();
    }
  };
  
  const goToPreviousPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
      fetchMessagesList();
    }
  };

 
  // Modify loadNextPageForViewDetailsModal and loadPreviousPageForViewDetailsModal

  const loadNextPageForViewDetailsModal = () => {
    console.log("load next message called")
    if (currentPage.value < totalPages.value) {
      console.log("reached end of page, load next page condition true")
      currentPage.value++;
      fetchMessagesList()
      selectedMessageIndex.value = 0; // Start at the first message of the new page
   
    }
  };

  const loadPreviousPageForViewDetailsModal = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
      fetchMessagesList()
      selectedMessageIndex.value = messageList.value.length - 1; // Start at the last message of the new page
    }
  };





  </script>
  
  <style scoped>
  /* Styling for loading spinner */
  .bg-conic-gradient {
    background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
  }
  
  .spinner-mask {
    --spinner-thickness: 3px;
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  }
  </style>
  