import { ref } from "vue";

const modalMapping = {
  individual: 'daily-form-modal-app',
}

const currentModalName = ref('')
const currentObservType = ref('')
const selectedChild = ref('')

export function openObservationForm(observType, student = '') {
  console.log("Tyring to open observaiton modal", {student})
  const modalName = modalMapping[observType]
  currentModalName.value = modalName;
  currentObservType.value = observType;
  if (student) {
    selectedChild.value = student;
  }
  console.log(currentModalName, currentObservType)

  HSOverlay.open(`#hs-${modalName}`)
}

export function useObservationModals() {
    const setCurrentModalName = (newName) => {
      currentModalName.value = newName;
    }

    const setCurrentObservType = (newType) => {
      currentObservType.value = newType;
    }

    const setSelectedChild = (newChild) => {
      selectedChild.value = newChild;
    }

    return {
      currentModalName, currentObservType,
      setCurrentModalName, setCurrentObservType,
      selectedChild, setSelectedChild
    }
}