<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] min-h-[50%] overflow-auto">
        
        <!-- Close Button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>

        <div v-if="learnerData">
  
            <div class="font-semibold text-[18px] mt-2 mb-3 px-4">
            Edit Learner Details
            </div>
    
            <!-- Page Content -->
            <div v-if="currentPage === 1" class="px-4">
      
              <!-- Learner Name -->
              <div class="mb-4">
                  <label for="learnerName" class="block text-sm font-medium">Learner Name</label>
                  <input v-model="learnerData.student_name" type="text" id="learnerName" required class="mt-1.5 text-sm block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              </div>
      
              <!-- Date of Birth -->
              <div class="mb-4">
                  <label for="dob" class="block text-sm font-medium">Date of Birth</label>
                  <input v-model="learnerData.date_of_birth" type="date" id="dob" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              </div>
      
              <!-- Cohort Multi-Select -->
              <div class="mb-4">
                  <label for="cohortSelect" class="block text-sm font-medium">Cohort</label>
                  <div class="relative mt-1.5 cursor-pointer border rounded">
                  <select v-model="learnerData.cohort" id="cohortSelect" ref="cohortSelect" :key="cohortSelectKey" data-hs-select='{
                      "placeholder": "Cohort",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' 
                  class="hidden">
                      <option v-for="cohort in optionsResponse.cohorts" :key="cohort.name" :value="cohort.name">{{ cohort.cohort_name }}</option>
                  </select>
                  </div>
              </div>
      
            </div>
    
            <div v-if="currentPage === 2" class="px-4">
              
            <!-- Parent 1 Names -->
            <div v-if="allParentsList.length>0" class="mb-4">  
              <label for="parent1Select" class="block text-sm font-medium">Parent 1 Name</label>
              <div class="relative mt-1.5 cursor-pointer border rounded">
              <select v-model="learnerData.parent_1.parent_id" id="parent1Select" ref="parent1Select" :key="parent1SelectKey" data-hs-select='{
                  "hasSearch":"true",
                  "placeholder": "parent 1",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                  }'  class="hidden">
                  <option v-for="parent in allParentsList" :key="parent.parent_id" :value="parent.parent_id">
                  {{ parent.parent_name }}</option>
              </select>
              </div>
            </div>
    
            <!-- Parent 2 Names-->
            <div class="mb-4">
                <label for="parent2Select" class="block text-sm font-medium">Parent 2 Name</label>
                <div class="relative mt-1.5 cursor-pointer border rounded">
                <select v-model="learnerData.parent_2.parent_id" id="parent2Select" ref="parent2Select" :key="parent2SelectKey" data-hs-select='{
                    "hasSearch":"true",
                    "placeholder": "parent 2",
                    "toggleTag": "<button type=\"button\"></button>",
                    "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                    "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                    "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                    "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }'  class="hidden">
                    <option v-for="parent in allParentsList" :key="parent.parent_id" :value="parent.parent_id">
                    {{ parent.parent_name }}</option>
                </select>
                </div>
            </div>

            <!-- Emergency Contacts -->
            <label class="block text-sm font-medium">Emergency Contacts</label>
            <div v-for="(contact, index) in learnerData.emergency_contact" :key="contact.id || index" class="flex flex-col gap-1.5 mb-4 mt-2">
              <div class="flex gap-x-2">
                <input v-model="contact.contact_name" :id="'contactName' + index" type="text" placeholder="Enter Name" class="block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              
                <input v-model="contact.phone_number" :id="'contactNumber' + index" type="text" placeholder="Phone Number"
                 class="block w-full rounded-md focus:ring-opacity-50"
                 :class="validatePhoneNumber(contact.phone_number) ? 'border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200' : 'border-red-600 focus:border-red-300 focus:ring focus:ring-red-200'">
              </div>

              <div class="flex">
                <input v-model="contact.relation_to_child" :id="'relationToChild' + index" type="text" placeholder="Relation to Child" class="block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                <!-- Add New Item Button -->
                <div class="mx-2 self-center">
                  <button type="button" @click="addNewInputField" class="flex items-center text-sm text-blue-600 hover:text-blue-800">
                    <div class="bg-slate-200 rounded-full w-6 h-6 flex items-center justify-center">
                      <span class="text-black text-lg leading-none">+</span>
                    </div>
                  </button>
                </div> 
              </div>  

            </div>
          
            </div>
    
            <div v-if="currentPage === 3" class="px-4">
            
            <!-- Additional Information -->
            <div class="mb-4">
                <label for="likes" class="block text-sm font-medium">Likes</label>
                <input v-model="learnerData.likes" type="text" id="likes" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
    
            <div class="mb-4">
                <label for="dislikes" class="block text-sm font-medium">Dislikes</label>
                <input v-model="learnerData.dislikes" type="text" id="dislikes" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
    
            <div class="mb-4">
                <label for="funFacts" class="block text-sm font-medium">Fun Facts</label>
                <input v-model="learnerData.fun_facts" type="text" id="funFacts" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>

             <!-- Food Preference Dropdown -->
             <div class="mb-4">
                <label for="foodPreference" class="block text-sm font-medium">Food Preference</label>
                <div class="relative mt-1.5 cursor-pointer border rounded">
                  <select v-model="learnerData.food_preference" id="foodPreference" ref="foodPreference" :key="foodPreferenceKey" data-hs-select='{
                      "placeholder": "Select Food Preference",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' 
                  class="hidden">
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non-Vegetarian">Non-Vegetarian</option>
                  </select>
                </div>
             </div>

              <!-- Allergies Text Input -->
              <div class="mb-4">
                <label for="allergies" class="block text-sm font-medium">Allergies</label>
                <input v-model="learnerData.allergies" type="text" id="allergies" placeholder="Enter allergies" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              </div>

            </div>
    
            <div v-if="currentPage === 4" class="px-4">
            
            <!-- Contact Preference -->
            <div class="mb-4">
                <label for="preferredContact" class="block text-sm font-medium">Which Contact Should Be Called First?</label>
                <div class="relative mt-1.5 cursor-pointer border rounded">
                  <select v-model="learnerData.contact_to_be_called_first" id="contactToBeCalledFirst" ref="contactToBeCalledFirst" :key="contactToBeCalledFirstKey" data-hs-select='{
                      "placeholder": "select",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' 
                  class="hidden">
                      <option key="parent_1" value="parent_1">Parent 1</option>
                      <option key="parent_2" value="parent_2">Parent 2</option>
                  </select>
                  </div>
            </div>
            
            <div class="mb-6">
                <label for="preferredContact" class="block text-sm font-medium">Upload photo for Tagging</label>
                <div class="text-slate-500 text-sm mt-2 mb-4 font-medium">This is only for Zippie's facial recognition and tagging. It won't be visible to anyone.</div>
                
                <!-- file input for image upload -->
                <input type="file" accept="image/*" ref="fileInput" @change="handleFileUpload" class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm disabled:opacity-50 disabled:pointer-events-none
                  file:bg-gray-100 file:border-0 file:me-4 text-gray-500 font-medium cursor-pointer"/>
            </div>


            <!-- Checkbox -->
            <div class="mb-4">
                <label for="isEnrolled" class="block text-sm font-medium">Is Enrolled</label>
                <input type="checkbox" v-model="learnerData.is_enrolled" id="isEnrolled" class="mt-1.5" 
                :true-value="1"
                :false-value="0">
            </div>

            </div>
            
    
            <!-- Progress Indicator -->
            <div class="flex justify-start gap-2 mx-4 flex">
              <div v-for="index in totalSteps" :key="index" class="self-center" :class="['w-7 h-2 rounded-sm', currentPage >= index ? 'bg-[#FFD700]' : 'bg-gray-200']"></div>
              <span class="text-xs font-medium text-gray-600">{{currentPage}}/4</span>
            </div>
    
            <!-- Navigation Buttons -->
            <div class="flex mt-4 mb-4 mx-4" :class="currentPage > 1 ? 'justify-between' : 'justify-end'">

              <!-- removed because can not find a way to rerender preline components -->
              <button @click="resetForm" type="button" class="py-2.5 px-3 text-sm font-medium text-black bg-white border border-gray-300 rounded-lg hover:bg-gray-100">
                Reset
              </button> 


            <button v-if="currentPage > 1" @click="goToPreviousPage" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer text-[14px] hover:bg-opacity-80 text-black bg-white" style="border: 1px solid #cbd5e1;">
                Back
            </button>
            <button v-if="currentPage < totalSteps" @click="goToNextPage" :disabled="!validateAllPhoneNumber && currentPage == 2" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">
                Next
            </button>
            <button v-else @click="submitForm" :disabled="!validateAllPhoneNumber ||isLoading" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">
              <span v-if="isLoading">Saving...</span>
              <span v-else>Save</span>
            </button>
            </div>

            <div v-if="isLoading" class="text-green-500 text-sm mt-4 mb-4 ml-4">Updating Learner details</div>
            <div v-if="errorMessage" class="text-red-500 text-sm mt-4 mb-4 ml-4">{{ errorMessage }}</div>
            

        </div>
  
        <!-- Spinner logo -->
        <div v-else>
            <div class="w-full h-full mt-[50%] md:mt-[37%] flex items-center justify-center z-50">
                <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
                    <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted,defineEmits, defineProps,inject, computed, nextTick } from 'vue';
  
  const emit = defineEmits(['close', 'submit-success']);
  const $call = inject('$call');
  

  const originalLearnerData = ref(null); // Store the original data

  const props = defineProps({
    optionsResponse: Object, // Contains available cohorts, parents, etc.
    studentId:String
  });

  // Keys for resetting the fields 
  const cohortSelectKey = ref(0);
  const parent1SelectKey = ref(0);
  const parent2SelectKey = ref(0);
  const foodPreferenceKey = ref(0);
  const contactToBeCalledFirstKey = ref(0);



  const isLoading = ref(false);
  const errorMessage = ref(null);  // To store error messages

  
  // State for navigation and field data
  const currentPage = ref(1);
  const totalSteps = 4;
  
  // Individual field bindings
  const learnerName = ref('');
  const dateOfBirth = ref('');
  const selectedCohort = ref(null);
  const selectedParent1 = ref(null);
  const selectedParent2 = ref(null);
  const emergencyContactName = ref('');
  const emergencyContactNumber = ref('');
  const likes = ref('');
  const dislikes = ref('');
  const funFacts = ref('');
  const preferredContact = ref('');
  const isEnrolled = ref(false);
  const allParentsList = ref([]);
  const faceRecognitionImage = ref(null);
  
  const learnerData = ref(null);
  
  onMounted(() => {
    // Initialize Preline selects
    console.log("this is learner id recieved",props.studentId)
    fetchAllParentsList();
    fetchLearnerDetails();
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
  
      const cohortSelectElement = window.HSSelect.getInstance('#cohortSelect');
      if (cohortSelectElement) {
        cohortSelectElement.on('change', (value) => {
          selectedCohort.value = value ? [...value] : [];
        });
      }
  
      const parent1SelectElement = window.HSSelect.getInstance('#parent1Select');
      if (parent1SelectElement) {
        parent1SelectElement.on('change', (value) => {
          selectedParent1.value = value;
        });
      }
  
      const parent2SelectElement = window.HSSelect.getInstance('#parent2Select');
      if (parent2SelectElement) {
        parent2SelectElement.on('change', (value) => {
          selectedParent2.value = value;
        });
      }
    }, 100);
  });



const fetchLearnerDetails = async () => {
  try {
    const response = await $call('curriculum_planner.curriculum_planner.common.learners.api.get_student_details', { student_id: props.studentId });
    learnerData.value = response;
    originalLearnerData.value = JSON.parse(JSON.stringify(response)); // Deep copy to preserve original data

    // Ensure at least one empty emergency contact if none exist
    if (!learnerData.value.emergency_contact || learnerData.value.emergency_contact.length === 0) {
      learnerData.value.emergency_contact = [{
        id: null,
        contact_name: '',
        phone_number: '',
        relation_to_child: ''
      }];
    }
    console.log("this is learners detailed fetched in form componen response:",response)
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    },100)
    
  } catch (error) {
    console.error('Error fetching student details:', error);
  }
};




  const fetchAllParentsList = async () => {
    try {

      const response = await $call('curriculum_planner.curriculum_planner.common.parents.api.get_all_parents_name_and_id');
      
      console.log("Fetched all parent name and id",response)
      allParentsList.value = response.parents
      console.log("All parents list variable",allParentsList.value)
    } catch (error) {
      console.error('Error fetching all parents list:', error);
    }
  };


const reinitializePrelineSelect = async () => {
  console.log("Reinitializing Preline selects...");

  // Destroy and reinitialize Cohort select
  const cohortSelect = window.HSSelect.getInstance('#cohortSelect');
  if (cohortSelect) {
    cohortSelect.destroy();
  }
  cohortSelectKey.value++;

  // Destroy and reinitialize Parent 1 select
  const parent1Select = window.HSSelect.getInstance('#parent1Select');
  if (parent1Select) {
    parent1Select.destroy();
  }
  parent1SelectKey.value++;

  // Destroy and reinitialize Parent 2 select
  const parent2Select = window.HSSelect.getInstance('#parent2Select');
  if (parent2Select) {
    parent2Select.destroy();
  }
  parent2SelectKey.value++;

  // Destroy and reinitialize Food Preference select
  const foodPreferenceSelect = window.HSSelect.getInstance('#foodPreference');
  if (foodPreferenceSelect) {
    foodPreferenceSelect.destroy();
  }
  foodPreferenceKey.value++;

  // Destroy and reinitialize Contact to be Called First select
  const contactToBeCalledFirstSelect = window.HSSelect.getInstance('#contactToBeCalledFirst');
  if (contactToBeCalledFirstSelect) {
    contactToBeCalledFirstSelect.destroy();
  }
  contactToBeCalledFirstKey.value++;

  await nextTick();
  window.HSStaticMethods.autoInit(); // Reinitialize all selects
};



const resetForm = () => {
  learnerData.value = JSON.parse(JSON.stringify(originalLearnerData.value));
  reinitializePrelineSelect();
};



  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log('Selected file:', file);
      convertFileToBase64(file);
    }
  };


const convertFileToBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64String = reader.result.split(',')[1]; // Remove the "data:image/*;base64," prefix
    faceRecognitionImage.value = base64String;
  };
  reader.onerror = (error) => {
    console.error('Error converting file to base64:', error);
  };
};




  const submitForm = async () => {
    try {
      isLoading.value = true;
      errorMessage.value = null;
      // Call the API with learnerData values
      const response = await $call('curriculum_planner.curriculum_planner.common.learners.api.update_student_details', {
        student_id: props.studentId,
        student_name: learnerData.value.student_name,
        date_of_birth: learnerData.value.date_of_birth,
        cohort: learnerData.value.cohort,
        likes: learnerData.value.likes,
        dislikes: learnerData.value.dislikes,
        fun_facts: learnerData.value.fun_facts,
        contact_to_be_called_first: learnerData.value.contact_to_be_called_first,
        is_enrolled: learnerData.value.is_enrolled,
        parent_1: learnerData.value.parent_1.parent_id,
        parent_2: learnerData.value.parent_2.parent_id,
        allergies: learnerData.value.allergies, 
        food_preference: learnerData.value.food_preference , 
        emergency_contact: learnerData.value.emergency_contact.map(contact => ({
          id:contact.id,
          contact_name: contact.contact_name,
          phone_number: contact.phone_number,
          relation_to_child: contact.relation_to_child,
        })),
        face_recognition_image: faceRecognitionImage.value,
      });
      
      if (response.status === 'success'){
        // Emit submit success event
        emit('submit-success');
        emit('close');
      }
      else {
        errorMessage.value = response.message || 'An error occurred while saving the learner details.';
        console.error('Error creating Learner:', error);
      }

    } catch (error) {
      errorMessage.value = 'An error occurred while saving the learner details.';
    }
    finally {
      isLoading.value = false;
    }
  };


  const validatePhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return true; // Allow empty fields
    const phonePattern = /^\+?[0-9]{1,3}?[0-9]{8,12}$/;
    return phonePattern.test(phoneNumber);
  };

  const validateAllPhoneNumber = computed(() => {
    return learnerData.value.emergency_contact.every(contact => {
      return validatePhoneNumber(contact.phone_number);
    });
  });

 

  const addNewInputField = () => {
    learnerData.value.emergency_contact.push({
      id: null,
      contact_name: '',
      phone_number: '',
      relation_to_child: ''
    });
  };



  const goToNextPage = () => {
    if (currentPage.value < totalSteps) {
      currentPage.value++;
    }
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    },100)
  };
  
  const goToPreviousPage = () => {
    if (currentPage.value > 1) {
      currentPage.value--;
    }
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    },100)
  };
  
  const closeComponent = () => {
    console.log("emmiting close")
    emit('close');
  };
  
  </script>
  


<style scoped>

/* Styling for loading spinner */
.bg-conic-gradient {
  background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
 }

.spinner-mask {
  --spinner-thickness: 3px;
  mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
}

input[type="file"]::file-selector-button {
  content: "Choose file"; 
  color: rgb(107 114 128);
  font-weight: 500;
  padding: 1rem 1.25rem; 
  border: none;
}



</style>
  