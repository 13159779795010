<template>
    <div class="flex flex-col gap-8">
      <div class=" rounded p-4 flex flex-col gap-4" v-if="props.countList?.length">
        <div :class="cn(`grid grid-cols-2 md:grid-cols-4 gap-4`, 
          Object.entries(cohortEmptyCounts).filter(([coh, count]) => count && count > 0).length > 4 && 'md:grid-cols-3')">
          <template v-for="cohort in props.cohortList.slice(1,)">
            <div class="bg-[#FEF3C2] rounded-lg p-4 text-sm space-y-4"
              v-if="cohortEmptyCounts[cohort.name]">
              <h3 class="font-semibold">
                {{ cohort.cohort_name }}
              </h3>
              <div v-for="student in props.countList">
                <!-- <router-link :to="`/front-portal/observations/view/child/all?student=${student.student_id}`"
                  class="cursor-pointer" v-if="(student.cohort === cohort.name) && (student.count === 0)">
                  <p>
                    {{ student.student_name }}
                  </p>
                </router-link> -->
                <template
                   v-if="(student.cohort === cohort.name) && (student.count === 0)">
                  <p class="cursor-pointer" @click="() => openObservationForm('individual', student.student_id)">
                    {{ student.student_name }}
                  </p>
                </template>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="props.countList">
        <div class="h-[50vh] w-full">
          <BarChart :categories="names" :values="observations" />
        </div>
      </div>
    </div>
</template>

<script setup>
import { cn } from '@/components/ui/utils.js';
import { ref, computed, inject } from 'vue';
import BarChart from '../../charts/BarChart.vue';
import { useObservationModals, openObservationForm } from '../../../lib/observations';

const props = defineProps([
  'type', 'countList', 'cohortList'
])

console.log("countlist", props.countList)

const cohort = ref('all')

const names = computed(() => {
  if (!props.countList) return []
  if (cohort.value === 'all')
    return props.countList.map((weeklyStat) => weeklyStat.student_name)
  return props.countList.filter((weeklyStat) => weeklyStat.cohort === cohort.value)
    .map((weeklyStat) => weeklyStat.student_name)
})
const observations = computed(() => { 
  if (!props.countList) return []
  if (cohort.value === 'all')
    return props.countList.map((weeklyStat) => weeklyStat.count)
  return props.countList.filter((weeklyStat) => weeklyStat.cohort === cohort.value)
    .map((weeklyStat) => weeklyStat.count)
})

const cohortEmptyCounts = computed(() => {
  if (!props.cohortList?.length || !props.countList?.length) return {}
  const cohortObj = {}
  // props.cohortList.slice(1,).forEach((cohort) => {
  //   cohortObj[cohort.name] = 0
  // })
  props.countList.forEach((studentCount) => {
    if (studentCount.count === 0) {
      if (!cohortObj[studentCount.cohort]) {
        cohortObj[studentCount.cohort] = 1;
      } else {
        cohortObj[studentCount.cohort] += 1;
      }
    }
  })
  return cohortObj;
})

console.log("cohemptycounts", cohortEmptyCounts)
</script>