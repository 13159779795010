<template>
  <div class="h-full md:h-[100%] overflow-hidden md:mx-8 md:w-[98%]">
    
    <div class="md:max-h-[20%] overflow-auto">
      <!-- Heading and add button -->
      <div class="flex mt-4 px-3 md:px-0 justify-between md:max-w-[1300px]">  
        <div class="text-xl md:text-2xl font-medium self-center w-full">User Management</div>
        
        <!-- Add Educators,Learners,Parents button and dropdown container -->
        <div class="md:hidden order-5 mt-2 ml-4 md:m-auto w-[50%] md:w-auto flex justify-end">
            <!-- Preline dropdown -->
            <div class="hs-dropdown relative inline-flex">
              
                <!-- Button -->
                <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle bg-[#FDD835] rounded-full w-10 h-10 flex items-center justify-center cursor-pointer" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                  <span class="text-black text-3xl leading-none flex text-center ">+</span>
                </button>
              

                <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full" role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-default">
                  <div class="p-1 space-y-0.5">
                    <div @click="showAddEducatorForm = true" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                      Add Educator
                    </div>
                    <div @click="showAddParentForm=true" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                      Add Parent
                    </div>
                    <div @click="showAddLearnerForm = true" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                      Add Learner
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>

      <!-- Tabs for Switching Views -->
      <div class="md:max-w-[1300px] md:flex justify-between">
        <div class="flex flex-row justify-center my-4 w-full md:w-[40%] md:mb-6 md:max-w-[450px]">
          <button @click="activeTab = 'educators'" :class="['rounded-l-md text-sm font-medium py-3 w-1/3', activeTab === 'educators' ? 'bg-black text-white' : 'bg-[#FDD835] text-black']">
            Educators
          </button>
          <button @click="activeTab = 'parents'" :class="['text-sm font-medium py-3 w-1/3', activeTab === 'parents' ? 'bg-black text-white' : 'bg-[#FDD835] text-black']">
            Parents
          </button>
          <button @click="activeTab = 'learners'" :class="['rounded-r-md text-sm font-medium py-3 w-1/3', activeTab === 'learners' ? 'bg-black text-white' : 'bg-[#FDD835] text-black']">
            Learners
          </button>
        </div>

         <!-- Add Educators,Learners,Parents button and dropdown container -->
         <div class="hidden md:flex flex justify-end items-center">
            <!-- Preline dropdown -->
            <div class="hs-dropdown relative inline-flex">
              
                <!-- Button -->
                <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle bg-[#FDD835] rounded-full w-10 h-10 flex items-center justify-center cursor-pointer" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
                  <span class="text-black text-3xl leading-none flex text-center ">+</span>
                </button>
              

                <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full" role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-default">
                  <div class="p-1 space-y-0.5">
                    <div @click="showAddEducatorForm = true" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                      Add Educator
                    </div>
                    <div @click="showAddParentForm=true" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                      Add Parent
                    </div>
                    <div @click="showAddLearnerForm = true" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                      Add Learner
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>

    </div>  

    <!-- Tab Content -->
    <div class="h-full md:h-auto max-h-[80%] md:max-h-[80%] overflow-auto">
      <div v-if="activeTab === 'educators'" class="h-full overlow-auto md:overflow-visible md:max-w-[1300px]">
        <!--EducatorsTab component-->
        <EducatorsTab :re-render-counter="reRenderEducator"></EducatorsTab>
      </div>

      <div v-if="activeTab === 'parents'" class="h-full md:max-w-[1300px]">
        <!--ParentsTab component -->
        <ParentsTab :options-response="props.optionsResponse" :re-render-counter="reRenderParent"></ParentsTab>
      </div>

      <div v-if="activeTab === 'learners'" class="h-full">
        <!-- Learners Tab component -->
        <LearnersTab :options-response="props.optionsResponse" :re-render-counter="reRenderLearner"></LearnersTab>
      </div>
    </div>

    <template v-if="showAddLearnerForm">
      <AddLearnerForm :options-response="props.optionsResponse" @close="showAddLearnerForm = false" @upload-success="handleLearnerUploadSuccess"></AddLearnerForm>
    </template>

    <template v-if="showAddEducatorForm">
      <AddEducatorForm @close="showAddEducatorForm = false" @upload-success="handleEducatorUploadSuccess"></AddEducatorForm>
    </template>

    <template v-if="showAddParentForm">
      <AddParentForm :options-response="props.optionsResponse" @close="showAddParentForm = false"  @upload-success="handleParentUploadSuccess"></AddParentForm>
    </template>
    
  </div>
</template>

<script setup>
import { ref } from 'vue';

import LearnersTab from '../components/userManagement/LearnersTab.vue';
import EducatorsTab from '../components/userManagement/EducatorsTab.vue';
import ParentsTab from '../components/userManagement/ParentsTab.vue';
import AddLearnerForm from '../components/userManagement/AddLearnerForm.vue';
import AddEducatorForm from '../components/userManagement/AddEducatorForm.vue';
import AddParentForm from '../components/userManagement/AddParentForm.vue';



const props = defineProps({
optionsResponse: Object,
});



// Set the active tab initially
const activeTab = ref('educators');
const showAddLearnerForm = ref(false);
const showAddEducatorForm = ref(false);
const showAddParentForm = ref(false);



const reRenderEducator = ref(0);
const reRenderLearner = ref(0);
const reRenderParent = ref(0);

const handleEducatorUploadSuccess = () => {
  reRenderEducator.value += 1;
};

const handleLearnerUploadSuccess = () => {
  reRenderLearner.value += 1;
};

const handleParentUploadSuccess = () => {
  reRenderParent.value += 1;
};

</script>



