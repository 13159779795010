<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] md:max-w-[550px] min-h-[50%] overflow-auto px-6 py-3">
        
        <!-- Close Button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
  
        
        <!-- Learner Profile -->
        <div v-if="learnerData !==null" class="mb-2">
            <!-- Profile Image -->
            <div class="flex flex-col items-center mb-6 relative">
              <img :src="learnerData.presigned_s3_url" alt="Profile Image" class="w-24 h-24 rounded-full object-cover" v-if="learnerData.presigned_s3_url && !isUploading"/>
             
              <!-- Loading Spinner (visible while uploading) -->
              <div v-if="isUploading" class="w-24 h-24 rounded-full flex items-center justify-center bg-gray-200">
                <span>Loading...</span> <!-- You can replace this with an actual spinner icon if needed -->
              </div>
             
              <!-- Edit student details Icon -->
              <div class="flex self-center absolute top-[50%] md:top-[50%] left-[60%] md:left-[57%] cursor-pointer" @click="openImageSelector">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3242 2.30541C13.5152 2.07968 13.7515 1.89595 14.018 1.76592C14.2845 1.63589 14.5753 1.56241 14.872 1.55017C15.1686 1.53793 15.4646 1.58719 15.741 1.69482C16.0174 1.80245 16.2682 1.96609 16.4774 2.1753C16.6867 2.38451 16.8497 2.63471 16.9561 2.90994C17.0626 3.18517 17.1101 3.47941 17.0957 3.77389C17.0813 4.06838 17.0052 4.35665 16.8724 4.62033C16.7396 4.88402 16.5529 5.11733 16.3242 5.30541L6.19922 15.4304L2.07422 16.5554L3.19922 12.4304L13.3242 2.30541Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.8242 3.80518L14.8242 6.80518" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
              </div>


              <!-- Hidden file input for image upload -->
              <input type="file" accept="image/*" ref="fileInput" class="hidden" @change="handleFileUpload" />

              <div v-if="errorMessage" class="text-red-500 text-sm mt-2">{{ errorMessage }}</div>

              <div class="font-semibold text-[17px] mt-2"> {{ learnerData.student_name }}</div>
              <div class="font-medium text-gray-500 text-sm mt-2">
                {{ calculateAge(learnerData.date_of_birth)}} | {{ learnerData.cohort_name }}
              </div>
            </div>
      
              <!-- Student Details -->
              <div v-if="learnerData.date_of_birth" class="mb-1">
                    <span class="font-medium mr-2"> Birthday:</span>
                    <span class="text-slate-500">{{ moment(learnerData.date_of_birth).format('DD MMMM') }}</span>
              </div>  

              <!-- Parent Information -->
              <div v-if="learnerData.parent_1.parent_name || learnerData.parent_2.parent_name" class="mb-1">
                <span class="font-medium mr-2"> Parents Names:</span>
                <span class="text-slate-500"> <template v-if="learnerData.parent_1">{{learnerData.parent_1.parent_name }}</template> & <template v-if="learnerData.parent_2">{{learnerData.parent_2.parent_name }}</template> </span>
              </div>

              <!-- preferred contact -->
                <div v-if="learnerData.contact_to_be_called_first" class="mb-1">
                  <span class="font-medium mr-2"> Contact to be Called First:</span>
                  <span class="text-slate-500"> {{ learnerData.contact_to_be_called_first }}</span>
                </div>   
              

              <!-- Emergency Contacts -->
              <div class="flex flex-col md:flex-row w-full mb-1">
                    <span class="font-medium mr-2 min-w-[175px]"> Emergency Contacts:</span>
                    <div v-if="learnerData.emergency_contact && learnerData.emergency_contact.length>0" class="w-full flex flex-col md:flex-row">
                        <div v-for="(contact, index) in learnerData.emergency_contact" :key="index" class="">
                            <span class="text-slate-500">{{ contact.contact_name }}: {{ contact.phone_number }}({{ contact.relation_to_child }}) </span>
                            <template v-if="index < learnerData.emergency_contact.length - 1"><span class="hidden md:inline text-slate-500 mr-1">,</span></template>
                        </div>
                    </div>
                    <span v-else class="text-slate-500">No emergency contacts available</span>
                </div> 
                
      
              <!-- Additional Information -->
              <div class="mb-1">
                    <span class="font-medium mr-2"> Food Preferences:</span>
                    <span class="text-slate-500">{{learnerData.food_preference }}</span>
                </div>  
                
                <div class="mb-1">
                    <span class="font-medium mr-2"> Allergies:</span>
                    <span class="text-slate-500">{{learnerData.allergies }} </span>
                </div> 

                <div class="mb-1">
                    <span class="font-medium mr-2"> Fun Facts about {{ learnerData.student_name }}:</span>
                    <span class="text-slate-500">{{learnerData.fun_facts }} </span>
                </div> 

            
                <!-- Current Likes and Dislikes Section -->
                <div class="font-medium mr-2 mb-1"> Current Likes & Dislikes:</div>
                <div v-if="learnerData.likes || learnerData.dislikes" class="flex flex-col gap-1 ml-3 text-sm">
                    <div v-if="learnerData.likes">
                    <span class="font-medium mr-2"> Likes:</span>
                    <span class="text-slate-500">{{ learnerData.likes }}</span>
                    </div> 
                    <div v-if="learnerData.dislikes">
                    <span class="font-medium mr-2"> Dislikes:</span>
                    <span class="text-slate-500">{{ learnerData.dislikes }}</span>
                    </div> 
                </div>
              
        </div>

        <!-- Spinner logo -->
        <div v-else>
            <div class="relative flex items-center justify-center w-full h-full z-50">
              
              <div class="absolute top-40 flex items-center justify-center">

                  <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
                      <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
                      <span class="sr-only">Loading...</span>
                  </div>

              </div>

            </div>
        </div>


      </div>
    </div>
  </template>
  
  <script setup>
 
  import { ref, onMounted,defineEmits,defineProps,inject, } from 'vue';
  import moment from 'moment';
  
  const $call = inject('$call');
  const isUploading = ref(false);
  const errorMessage = ref(null);  // To store error messages

  
  
  // Props and Emits
  const props = defineProps({
    studentId: {
      type: String,
      required: true
    }
  });
  const emit = defineEmits(['close','pfp-update-success']);
  
  // State
  const learnerData = ref(null);
  
  
  // Fetch student details
  const fetchLearnerDetails = async () => {
    try {
      const response = await $call('curriculum_planner.curriculum_planner.common.learners.api.get_student_details', { student_id: props.studentId });
      learnerData.value = response;
    } catch (error) {
      console.error('Error fetching student details:', error);
    }
  };


const fileInput = ref(null);

const openImageSelector = () => {
  fileInput.value.click(); // Programmatically open the file input
};

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    console.log('Selected file:', file);
    convertFileToBase64(file);
  }
};

const convertFileToBase64 = (file) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64String = reader.result.split(',')[1]; // Remove the "data:image/*;base64," prefix
    uploadProfilePicture(base64String);
  };
  reader.onerror = (error) => {
    console.error('Error converting file to base64:', error);
  };
};

const uploadProfilePicture = async (base64Image) => {
  try {
    isUploading.value = true;
    errorMessage.value = null;
    const response = await $call('curriculum_planner.curriculum_planner.common.learners.api.upload_students_profile_photo_to_s3_from_frontportal', {
      docname: props.studentId,
      image_data: base64Image,
    });

    if (response.status === 'success') {
      // Re-fetch learner details to update the image and emit success event
      fetchLearnerDetails();
      emit('pfp-update-success');
    } else {
      errorMessage.value = response.message || 'An error occurred while uploading the profile picture.';
    }
  } catch (error) {
    errorMessage.value = 'An error occurred while uploading the profile picture.';
    console.error('Error updating profile picture:', error);
  } finally {
    isUploading.value = false;
  }
};


  const calculateAge = (dateOfBirth) => {
        if (!dateOfBirth) {
            return 'NA';
        }

        const dob = new Date(dateOfBirth);
        if (isNaN(dob.getTime())) {
            return 'NA';
        }

        const now = new Date();
        const yearsDiff = now.getFullYear() - dob.getFullYear();
        const monthsDiff = now.getMonth() - dob.getMonth();

        let years = yearsDiff;
        let months = monthsDiff;

        if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
            years--;
            months = 12 + monthsDiff;
        }

        if (years === 0) {
            return `${months} month${months !== 1 ? 's' : ''}`;
        } else if (months === 0) {
            return `${years} year${years !== 1 ? 's' : ''}`;
        } else {
            return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`;
        }
    };
  
  // Close component function
  const closeComponent = () => {
    emit('close');
  };
  
  // Fetch data when the component is mounted
  onMounted(() => {
    fetchLearnerDetails();
  });
  </script>




<style scoped>

/* Styling for loading spinner */
.bg-conic-gradient {
  background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
 }

.spinner-mask {
  --spinner-thickness: 3px;
  mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
}


</style>
  