<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto h-full">
        <div class="">
          <div class="flex justify-between items-center px-4 pt-4">
            <h3 class="font-bold text-[#6B7280] text-lg md:text-xl">
              Generate Week Overview
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
        </div>
        <div class="border-b border-gray-200 dark:border-neutral-700 p-2 px-4">
          <nav class="flex space-x-1" aria-label="Tabs" role="tablist">
            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent 
              hs-tab-active:text-blue-600 dark:hs-tab-active:bg-neutral-800 
              dark:hs-tab-active:border-b-gray-800 dark:hs-tab-active:text-white -mb-px py-3 px-4 
              inline-flex items-center gap-x-2 bg-gray-50 text-sm font-medium text-center border 
              text-gray-500 rounded-t-lg hover:text-gray-700 disabled:opacity-50 disabled:pointer-events-none 
              ark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400 active" 
              id="overview-tab-item-1" data-hs-tab="#overview-tab-1" 
              aria-controls="card-type-tab-1" role="tab">
              Generate
            </button>
            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent 
              hs-tab-active:text-blue-600 dark:hs-tab-active:bg-neutral-800 
              dark:hs-tab-active:border-b-gray-800 dark:hs-tab-active:text-white -mb-px py-3 px-4 
              inline-flex items-center gap-x-2 bg-gray-50 text-sm font-medium text-center border 
              text-gray-500 rounded-t-lg hover:text-gray-700 disabled:opacity-50 disabled:pointer-events-none 
              ark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400" 
              id="overview-tab-item-2" data-hs-tab="#overview-tab-2" 
              aria-controls="card-type-tab-2" role="tab">
              New Prompt
            </button>
            <button type="button" class="hs-tab-active:bg-white hs-tab-active:border-b-transparent 
              hs-tab-active:text-blue-600 dark:hs-tab-active:bg-neutral-800 
              dark:hs-tab-active:border-b-gray-800 dark:hs-tab-active:text-white -mb-px py-3 px-4 inline-flex 
              items-center gap-x-2 bg-gray-50 text-sm font-medium text-center border text-gray-500 
              rounded-t-lg hover:text-gray-700 disabled:opacity-50 disabled:pointer-events-none 
              dark:bg-neutral-700 dark:border-neutral-700 dark:text-neutral-400" id="overview-tab-item-3" 
              data-hs-tab="#overview-tab-3" aria-controls="card-type-tab-2" role="tab">
              Load Prompt
            </button>
          </nav>
        </div>


        <div class="mt-3">
          <div id="overview-tab-1" role="tabpanel" aria-labelledby="overview-tab-item-1" 
            class='p-4 max-h-[75vh] overflow-y-auto'>
            <div v-if="isGenerating">Generating overview, please wait...</div>
            <div class="bg-[#FFFAE6] p-4 rounded-md space-y-4">
              <div class="flex flex-col gap-1">
                <div class="flex justify-end gap-4">
                  <button type="button" @click="hideFullPrompt = true" 
                    v-if="fullPromptResource.data?.status === 'success' && !hideFullPrompt"
                    class="py-3 px-4 inline-flex items-center gap-x-2
                    text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                    hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                    Hide Full Prompt
                  </button>
                  <button type="button" @click="fetchFullPrompt"
                    class="py-3 px-4 inline-flex items-center gap-x-2
                    text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                    hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                    Show Full Prompt
                  </button>
                  <button type="button" @click="generateOverview"
                    class="py-3 px-4 inline-flex items-center gap-x-2
                    text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                    hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                    Generate
                  </button>
                </div>
                <div :class="cn(`cursor-pointer`, !promptExpanded && 'text-ellipsis line-clamp-4 text-gray-500')"
                  @click="promptExpanded = !promptExpanded">
                  Prompt: {{ promptName }}
                </div>
                <div :class="cn(`cursor-pointer`, !promptExpanded && 'text-ellipsis line-clamp-4 text-gray-500')"
                  @click="promptExpanded = !promptExpanded">
                  {{ promptDisplay }}
                </div>
                <button type="button" @click="hideFullPrompt = true" 
                  v-if="fullPromptResource.data?.status === 'success' && !hideFullPrompt"
                  class="py-3 px-4 inline-flex items-center gap-x-2
                  text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
                  hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit">
                  Hide Full Prompt
                </button>
                <div>Using Claude 3.5 Sonnet</div>
              </div>
              <div v-if="fullPromptResource.data?.status === 'success' && !hideFullPrompt"
                class="flex flex-col gap-2">
                <h4 class="font-semibold">Full Prompt:</h4>
                <div>
                  {{ fullPromptResource.data.prompt }}
                </div>
              </div>
            </div>
          </div>
          <div id="overview-tab-2" class="hidden" role="tabpanel" aria-labelledby="overview-tab-item-2">
            <form @submit.prevent="handleSubmit" class="flex-auto flex flex-col gap-4 p-4" ref="formRef">
              <div class="flex flex-col gap-4">
                <div>
                  <label class="font-semibold">Name: 
                    <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                      focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none"
                      name="name" v-model="newPromptName" placeholder="Untitled Prompt">
                  </label>
                </div>
                <div class="space-y-4">
                  <label class="font-semibold">Prompt: </label>
                  <textarea id="hs-autoheight-textarea-1" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm
                    focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" rows="10"
                    placeholder="Enter prompt here..." name="prompt" v-model="newPrompt" ref="promptInputRef"
                    @input="textareaAutoHeight"></textarea>
                </div>
              </div>
              <div>
                <span v-if="isSubmitting" class="font-semibold text-gray-600">
                  Saving Prompt...
                </span>
                <span v-if="!isSubmitting && isSaved" class="font-semibold text-gray-600">
                  Prompt Saved Successfully!
                </span>
              </div>
              <div class="flex gap-4 justify-end">
                <button type="submit" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                  dark:focus:ring-gray-600`)" :disabled="isSubmitting">
                  Save
                </button>
                <button type="button" :class="cn(`py-2 px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                  rounded-lg border border-transparent bg-gray-800 text-white hover:bg-gray-900 text-white
                  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                  dark:focus:ring-gray-600`)" :disabled="isSubmitting" @click="() => saveAndChangePrompt()">
                  Save & Use Prompt
                </button>
              </div>
            </form>
          </div>
          <div id="overview-tab-3" class="hidden" role="tabpanel" aria-labelledby="overview-tab-item-3">
            <div v-if="loadPromptResource.data?.status === 'success'" 
              class="flex flex-col gap-2 min-h-96 lg:max-h-[50vh] p-4 overflow-auto">
              <div v-for="prompt in loadPromptResource.data.prompts"
                class="border rounded-md p-2">
                <div class="flex gap-4">
                  <h5 class="font-semibold text-gray-700">
                    {{ prompt.prompt_name }}
                  </h5>
                  <p v-if="loadPromptResource.data.default_prompt === prompt.prompt_name" class="text-sm text-yellow-500 font-semibold">
                    default
                  </p>
                </div>
                <p class="text-ellipsis line-clamp-2 font-light text-gray-600">
                  {{ prompt.prompt }}
                </p>
                <div class="flex justify-end gap-4">
                  <button  class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
                    border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50
                    disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                    dark:hover:bg-neutral-800"
                  @click="() => makePromptDefault(prompt.name)">
                    Make Default
                  </button>
                  <button  class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg
                    border border-gray-200 bg-white text-yellow-500 shadow-sm hover:bg-gray-50
                    disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                    dark:hover:bg-neutral-800"
                  @click="() => changePrompt(prompt)">
                    Use this prompt
                  </button>
                </div>
              </div>
              <div v-if="!loadPromptResource.data.prompts.length">
                No Existing Prompts found!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, toDisplayString, watch } from 'vue'
import { cn } from '@/components/ui/utils.js'
import { HSOverlay } from 'preline/preline';
import { createResource } from 'frappe-ui'

const props = defineProps([
  'modalName', 'today', 'reloadData'
])

const emit = defineEmits(['change:defaultPrompt'])

const promptName = ref('')
const prompt = ref('')

const newPromptName = ref('')
const newPrompt = ref('')

const formRef = ref(null)
const promptInputRef = ref(null)

onMounted(() => {
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const usePrompt = (prompt) => {
  prompt.value = prompt.prompt
  promptName.value = prompt.name1
}

const changePrompt = (newPrompt) => {
  usePrompt(newPrompt)
  // HSOverlay.close(`#hs-${props.modalName}`)
}

const savePromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompts.api.save_prompt',
})

const loadPromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompts.api.get_all_prompts',
  params:{
    summary_type: 'Weekly Overview',
  },
  auto: true
})

const promptDisplay = computed(() => {
  if (prompt.value) return prompt.value;
  else if (loadPromptResource.data?.prompts?.length) {
    const defaultPrompt = loadPromptResource.data.default_prompt;
    const resultItem = loadPromptResource.data.prompts.find(item => item.name = defaultPrompt)
    if (resultItem) {
      promptName.value = resultItem.name1;
      return resultItem.prompt;
    }
  }
  return ''
})

const defaultPromptResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.summary_generation_prompts.api.make_default',
})

const isSubmitting = ref(false)
const isSaved = ref(false)

const newPromptID = ref('')

const handleSubmit = async () => {
  isSaved.value = false
  const formData = new FormData(formRef.value)
  const name = formData.get('name')
  const promptText = formData.get('prompt')

  console.log({name, promptText})
  const promptData = {
    prompt_name: name,
    prompt: promptText,
  }
  if (!promptText) {
    alert('Prompt cannot be empty!')
    return
  }
  if (!promptName) {
    alert('Prompt name cannot be empty!')
    return
  }
  isSubmitting.value = true
  try {
    await savePromptResource.submit({
      prompt_data: promptData,
      summary_type: 'Weekly Overview',
    })
    if (savePromptResource.data?.status === 'success') {
      console.log(savePromptResource.data.message)
      isSaved.value = true
      newPromptID.value = savePromptResource.data.prompt_id || ''
      setTimeout(() => {
        isSaved.value = false
      }, 3000)
      loadPromptResource.reload()
      promptName.value = ''
      prompt.value = ''
      // HSOverlay.close(`#hs-${props.modalName}`)
    } else {
      console.error(savePromptResource.data.message)
    }
    console.log(savePromptResource.data.message)
  } catch (error) {
    console.error(error)
  }
  isSubmitting.value = false
}

const saveAndChangePrompt = async () => {
  const promptText = newPrompt.value;
  const promptTitle = newPromptName.value;
  try {
    await handleSubmit();
    // changePrompt({
    //   name: newPromptID.value,
    //   prompt: promptText,
    //   prompt_name: promptTitle,
    // });
    promptName = newPromptName.value
    prompt.value = newPrompt.value
  } catch (error) {
    console.error("Failed to make save request", error)
  }
}

const textareaAutoHeight = () => {
  const windowHeight = window.innerHeight
  const offsetTop = 2
  promptInputRef.value.style.height = 'auto'
  let calculatedHeight = promptInputRef.value.scrollHeight + offsetTop
  if (calculatedHeight > windowHeight / 2.5)
    calculatedHeight = windowHeight / 2.5
  promptInputRef.value.style.height = `${calculatedHeight}px`
}

const makePromptDefault = async (prompt_name) => {
  try {
    await defaultPromptResource.submit({
      prompt_name
    })

    if (defaultPromptResource.data.status === 'fail') {
      console.log('Could not make prompt default. Try again')
    } else {
      emit('change:defaultPrompt')
      console.log('Prompt made defalt successfully')
      // if (props.prompt?.name === prompt_id) {
        // props.usePrompt({
        //   prompt: props.prompt.prompt,
        //   prompt_name: props.prompt.prompt_name,
        //   name: prompt_id,
        //   is_default: true,
        // })
      // }
      loadPromptResource.reload()
    }
  } catch (error) {
    console.error("Failed to make default request")
    console.error(error)
  }
}

const hideFullPrompt = ref(true)

const fullPromptResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.weekly.api.get_full_prompt',
})

const fetchFullPrompt = async () => {
  try {
    console.log("Today: ", props.today)
    await fullPromptResource.submit({
      date: props.today
    })
    if (fullPromptResource.data?.status === 'success') {
      console.log('Successfully fetched full prompt', fullPromptResource)
      hideFullPrompt.value = false
    } else {
      console.log(fullPromptResource.data.message)
    }
  } catch (error) {
    console.log(`Failed to make prompt request: ${error}`)
  }
}

const generateWeeklyOverviewResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.weekly.api.generate_weekly_summary',
})

const isGenerating = ref(false)
const generateOverview = async () => {
  try {
    isGenerating.value = true
    await generateWeeklyOverviewResource.submit({
      date: props.today
    })
    if (generateWeeklyOverviewResource.data?.status === 'success') {
      console.log('Successfully generated', generateWeeklyOverviewResource)
      if (props.reloadData) props.reloadData();
      HSOverlay.close(`#hs-${props.modalName}`)
      // hideFullPrompt.value = false
    } else {
      console.log(fullPromptResource.data.message)
    }
  } catch (error) {
    console.log(`Failed to make generate request: ${error}`)
  }
  isGenerating.value = false
}
</script>