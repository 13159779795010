<template>
  <div class="grid grid-cols-1 md:grid-cols-3">
    <!-- Hello
{{ scores }} -->
    <template v-for="cohort in cohortList">
      <div v-if="scores[cohort]" class="" >
        <div class="h-[14rem] lg:h-[20rem]">
          <BarChart :categories="competencies" :values="scores[cohort]" :color="colors" 
            :inverse="true" :showNames="false" :showYTick="false" barCategoryGap="5%" />
        </div>
        <p class="font-medium text-xs flex justify-center">{{ cohort }}</p>
      </div>
    </template>
  </div>

</template>

<script setup>
import { computed, inject } from 'vue';
import BarChart from '../../../charts/BarChart.vue';

const props = defineProps(['activityData'])

const cohortList = [
  "BigKids",
  "Bombils",
  "Bimblis",
  // "Batatas",
  "Batatas-1",
  "Batatas-2",
]

const competencies = [
  "Storytelling",
  "Critical Thinking",
  "Emotional Intelligence",
  "Fine Motor Skills",
  "Math & Numeracy",
  "Physical Wellness",
  "Reading & Writing",
  "Science Exploration",
  "Music & Rhythm",
  "Visual Arts",
  "Others",
]

const colors = ['#F5CCE8', '#9A79A6', '#7B2D26', '#FB4D3D', '#F18D01', '#FFC000', '#FFEBAE', '#8DB580', '#D3FCD5', '#70E4EF', '#345995']

const userDetails = inject('userDetails')
console.log("Activity user", userDetails)

const scores = computed(() => {
  const scoresObj = {}
  console.log(userDetails.value)
  if (!userDetails.value?.school_info?.cohorts) return scoresObj;
  console.log("Activity user present")
  if (!props.activityData?.competency_scores) {
    const emptyArr = new Array(competencies.length).fill(0)
    userDetails.value.school_info.cohorts.forEach(cohort => {
      if (cohortList.includes(cohort.cohort_name)) {
        console.log("Activity calc for cohort", cohort)
        scoresObj[cohort.cohort_name] = emptyArr;
      }
    });
  } else {
    userDetails.value.school_info.cohorts.forEach(cohort => {
      if (cohortList.includes(cohort.cohort_name)) {
        scoresObj[cohort.cohort_name] = Object.entries(props.activityData.competency_scores[cohort.name]).map(([key, value]) => {
          return value
        })
      }
    })
  }
  return scoresObj;
})

console.log("ACtivity scores", scores)
</script>