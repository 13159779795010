import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import UserHome from "../views/UserHome.vue";
import authRoutes from './auth';
import EducatorDashboard from '@/views/EducatorDashboard.vue';
import Calendar from '@/views/Calendar.vue';
import PublicCalendar from '@/views/PublicCalendar.vue'
import Observations from '@/views/Observations.vue'
import PhotoEssays from '@/views/PhotoEssays.vue'
import Activities from "../views/Activities.vue";
import DomainView from "@/components/activities/view/DomainView.vue";
import AllActivities from '@/components/activities/AllActivities.vue';
import SearchView from '@/components/activities/search/SearchView.vue';
import ImportView from '@/components/activities/imported/ImportView.vue';
import Educators from '@/views/Educators.vue';
import UserManagement from '@/views/UserManagement.vue';
import AdminControls from '@/views/AdminControls.vue';
import AllObservations from '@/views/AllObservations.vue';
import ObservationStatistics from '@/components/observations/stats/StatsPage.vue'
import SummaryPage from "../components/observations/summary/overview/SummaryPage.vue";
import ParentDashboard from '@/views/ParentDashboard.vue'
import StudentReport from "@/views/StudentReport.vue";
import ObservationSummary from "../views/ObservationSummary.vue";
import IndividualSummary from "../views/IndividualSummary.vue";
import StudentReports from "@/components/observations/summary/individual/StudentReports.vue";
import ObservationLink from "@/views/ObservationLink.vue";
import ActivityExtract  from '@/views/ActivityExtract.vue';
import AssessmentReport from '@/components/observations/summary/individual/assessment/AssessmentReport.vue';
import Sandbox from "@/views/Sandbox.vue";
import Learners from "../views/Learners.vue";
import Worksheets from "../views/Worksheets.vue";
// import WeekPlan from "@/views/WeekPlan.vue";
import WorksheetDetails from "../views/WorksheetDetails.vue";
import AdminLogin from "../views/AdminLogin.vue";
import Resources from "../views/Resources.vue";
import CurriculumLandscapeMap from "../views/CurriculumLandscapeMap.vue";
import CurriculumLandscapeTable from "../views/CurriculumLandscapeTable.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import Reports from '@/views/Reports.vue';
import UserLogin from "../views/UserLogin.vue";
import LunchMenu from "../views/LunchMenu.vue";
import LearnersList from "../views/LearnersList.vue";
import LearnerDetails from "../views/LearnerDetails.vue";
import ParentMessages from "../views/ParentMessages.vue";



// Shared route definition
const homeRouteDefinition = {
  meta: { requiresAuth: true },
  beforeEnter: async (to, from, next) => {
    const userRoles = await fetchUserRole();
    const roleChangedTo = localStorage.getItem('roleChangedTo');

    if (roleChangedTo === 'Parent' && userRoles.includes('Parent')) {
      next({ name: 'ParentDashboard' });
    } else if (userRoles.includes('Guest Facilitator')) {
      next({ name: 'Observations' });
    } else if (userRoles.includes('Educator')) {
      next({ name: 'Observations' });
    } else if (userRoles.includes('Parent')) {
      next({ name: 'ParentDashboard' });
    } else {
      next({ name: 'EducatorDashboard' }); // Default redirection
    }
  },
};



const routes = [
  {
	path: "/front-portal",  // By default it was this --path: "/front-portal",
	name: "Home",
	component: Home,
  ...homeRouteDefinition
  },
  {
    path: "/front-portal/home",  // By default it was this --path: "/front-portal",
    name: "UserHome",
    component: UserHome,
    ...homeRouteDefinition
  },
  ...authRoutes,
  {
    path: '/front-portal/educator-dashboard',
    name: 'EducatorDashboard',
    component: EducatorDashboard,
    meta: { requiresAuth: true } // if authentication is required
  },
  {
    path: '/front-portal/calendar', // URL path for the calendar page
    name: 'Calendar',  // A name for the route, which is optional but useful
    component: Calendar, // The component to render for this route
    meta: { requiresAuth: true }
  },
  {
    path: '/front-portal/calendar/plan-week',
    name: 'WeekPlan',
    component: () => import('@/views/WeekPlan.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/public-calendar', // URL path for the calendar page
    name: 'PublicCalendar',  // A name for the route, which is optional but useful
    component: PublicCalendar, // The component to render for this route
    meta: { requiresAuth: false }
  },
  {
    path: '/front-portal/observations', // URL path for the calendar page
    name: 'Observations',  // A name for the route, which is optional but useful
    component: Observations, // The component to render for this route
    meta: { requiresAuth: true }
  },
  {
    path: '/front-portal/observations/view/:type/:educator',
    name: 'ObservationsView',
    component: AllObservations,
    meta: { requiresAuth: true },
    props: route => ({
      search: route.query.search,
      student: route.query.student
    })
  },
  {
    path: '/front-portal/observations/statistics',
    name: 'ObservationStatistics',
    component: ObservationStatistics,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/summaries',
    name: 'SummaryPage',
    component: SummaryPage,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/photo-essays', // URL path for the calendar page
    name: 'PhotoEssays',  // A name for the route, which is optional but useful
    component: PhotoEssays, // The component to render for this route
    meta: { requiresAuth: true }
  },
  {
    path: '/front-portal/activities',
    name: 'Activities',
    component: Activities,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: AllActivities,
      },
      {
        path: 'domain/:domainId',
        component: DomainView,
      },
      {
        path: 'search/:searchKey',
        component: SearchView,
      },
      {
        path: 'imported',
        component: ImportView,
      },
    ]
  },
  {
    path: '/front-portal/educators',
    name: 'Educators',
    component: Educators,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/user-management',
    name: 'UserManagement',
    component: UserManagement,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/admin-controls',
    name: 'AdminControls',
    component: AdminControls,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/parent-dashboard', // URL path for the Parent Dashboard page
    name: 'ParentDashboard',  // A name for the route, which is optional but useful
    component: ParentDashboard, // The component to render for this route
    meta: { requiresAuth: true }
  },
  {
    path: '/front-portal/observations/report',
    name: 'Reports',
    component: Reports,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/report/:student',
    name: 'StudentReport',
    component: StudentReport,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/summary',
    name: 'ObservationSummary',
    component: ObservationSummary,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/summary-individual',
    name: 'IndividualSummary',
    component: IndividualSummary,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/summary-individual/report',
    name: 'IndividualSummaryReport',
    component: StudentReports,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/observation-link',
    name: 'ObservationLink',
    component: ObservationLink,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/activity-extract',
    name: 'ActvityStudentExtract',
    component: ActivityExtract,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/observations/assessment/student/:student',
    name: 'AssessmentReport',
    component: AssessmentReport,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/sandbox',
    name: 'Sandbox',
    component: Sandbox,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/learners',
    name: 'Learners',
    component: Learners,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/learners-list',
    name: 'LearnersList',
    component: LearnersList,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/learners-list/:learnerId',
    name: 'LearnerDetails',
    component: LearnerDetails,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/front-portal/worksheets',
    name: 'Worksheets',
    component: Worksheets,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/worksheets/:worksheetName',
    name: 'WorksheetDetails',
    component: WorksheetDetails,
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: '/front-portal/admin-login',
    name: 'AdminLogin',
    component: AdminLogin,
    meta: { requiresAuth: false },
  },
  {
    path: '/front-portal/user-login',
    name: 'UserLogin',
    component: UserLogin,
    meta: { requiresAuth: false },
  },
  {
    path: '/front-portal/resources',
    name: 'Resources',
    component: Resources,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/curriculum-landscape-map',
    name: 'CurriculumLandscapeMap',
    component: CurriculumLandscapeMap,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/curriculum-landscape-table/:domain?/:subdomain?/:devStage?/:focusArea?/:learningSequence?',
    name: 'CurriculumLandscapeTable',
    component: CurriculumLandscapeTable,
    props:true,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/terms-of-use',
    name: 'TermsOfUse',
    component: TermsOfUse,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/lunch-menu',
    name: 'LunchMenu',
    component: LunchMenu,
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/admin/messaging',
    name: 'AdminMessaging',
    component: () => import('@/views/AdminMessages.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/front-portal/parent-messages',
    name: 'ParentMessages',
    component: ParentMessages,
    meta: { requiresAuth: true },
  },
];


const router = createRouter({
  base: "/front-portal/",
  history: createWebHistory(),
  routes,
});


router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100)
  }
});

const fetchUserRole = async () => {
  try {
    const response = await fetch('/api/method/curriculum_planner.curriculum_planner.doctype.curriculum_events.curriculum_events.get_logged_in_user_profile', {
      method: 'GET',
      headers: {
        'X-Frappe-Csrf-Token': window.csrf_token
      }
    });

    if (response.ok) {
      const data = await response.json();
      if (data.message && data.message.role) {
        console.log("User profile fetch results in index.js 0000000000000000000000000:", data.message.role);
        return data.message.role;
      } else {
        console.error('User role not found in the response');
      }
    } else {
      console.error('Error fetching user role:', response.status);
    }
  } catch (error) {
    console.error('Error fetching user role:', error);
  }
};


export default router;
