<template>
  <div class="flex flex-col gap-4">
    <div v-for="(question, index) in props.unansweredList.slice(0, 5)" class="flex gap-2">
      <div class="flex border rounded-md shadow-sm">
        <div class="flex justify-center items-center px-4">{{ index + 1 }}</div>
        <div class="flex flex-col px-3 border-l py-2">
          <p class="font-medium text-sm lg:text-base">{{ question.question }}</p>
          <p class="font-medium text-xs text-gray-600">
            {{ question.type === 'weekly' ? "Weekly Child Observations" : "Group/Activity Observations" }}
          </p>
        </div>
      </div>
      <div class="bg-[#FFEBAE] rounded-md flex flex-col justify-center items-center gap-1 px-2">
        <p class="font-medium">
          {{ (question.count / question.total) * 100 }}%
        </p>
        <p class="text-[0.5rem] text-gray-600">skipped</p>
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps(['unansweredList'])
</script>