<template>
  <!--Modal-->
  <div @click.self="closeUploadPhotosModal" class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
     <!-- Form container with white background -->
     <div v-if="showUploadPhotosForms" class="bg-white rounded-lg shadow-md w-11/12 md:w-auto h-auto max-h-[97vh] overflow-auto flex md:flex-row flex-col relative">      
      
      <!-- Closing cross for large screens -->
      <div class="hidden md:block absolute top-0 right-0 md:mt-2 md:mr-2" @click="closeUploadPhotosModal">
        <svg class="w-[25px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>

    <!--Buttons/tabs and closing cross  -->
    <div class="flex flex-row mr-4 mt-4 ml-5">

        <!-- Show create-new-album card button -->
        <div class="block md:hidden flex felx-row justify-end mr-4 border border-slate-200">
          <button @click="toggleNewAlbumForm" :class="[showNewAlbumForm ? 'bg-black text-white' : 'bg-white text-black', 'text-xs font-bold rounded py-3 px-6']">
          Create New
          </button>
        </div>

        <!-- Show Add-to-existing-album card button -->
        <div class="block md:hidden flex felx-row justify-end border border-slate-200 mr-4">
          <button @click="toggleExistingAlbumForm" :class="[showExistingAlbumForm ? 'bg-black text-white' : 'bg-white text-black', 'text-xs font-bold py-3 px-6']">
            Add to Existing
          </button>
        </div>

        <!-- Closing cross for phone screens -->
        <div class="md:hidden mb-2 ml-2 " @click="closeUploadPhotosModal">
          <svg class="w-[22px] md:w-[25px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>

    </div>


       <!-- Create-New-Album card -->
       <form :class="[showNewAlbumForm ? 'block' : 'hidden', 'md:block']" @submit.prevent="handleSubmit">
         <div class="flex flex-row p-5 gap-4 mt-4">
            
            <div class="flex flex-col gap-2 border border-slate-200 p-2 rounded max-w-[100%]">
                <span class="text-black font-semibold">Create a New Album</span>
                <!-- Input Imges -->
                <input type="file" id="photoUpload" ref="photoUpload" multiple  class="hidden" accept="image/*" @change="handleFileChange"
/>

                <div style="background-color: #FEF3C2;border: 1px dashed #EAB308;" class="drop-area text-xs md:text-s rounded py-3 px-3 text-black border border-slate-300 rounded-md h-[10rem] lg:h-[15rem] flex flex-col items-center justify-center"
                    @dragover.prevent="handleDragOver"
                    @dragleave.prevent="handleDragLeave"
                    @drop.prevent="handleDrop"> 
                    <img src="@/assets/upload_photos_logo.png" alt="upload_photos_logo" height="44.7" width="70" style="max-width: none;">
                    <div class="font-medium">
                      <span style="color: #1F2937;" class="mr-1">Drop your images here or</span>
                      <span @click="triggerFileInput" style="color: #EAB308;">click to upload</span>
                    </div>
                    <span style="color: #AFAD9C;">Maximum size: 50MB ?</span>
                 </div>

                <!--Old Labels field for text inputs -->
                <!-- <input v-model="labels" class="border border-slate-200 rounded-md" type="text" id="tags" placeholder="Labels">
                 -->
                 
                 <!-- Autocomplete Labels Drop drown -->
                 <AutoCompleteDropDown @update-tags="handleUpdateTags"></AutoCompleteDropDown>
     
                <!-- Description -->
                <div class="border border-slate-200 rounded-md">
                    <textarea v-model="description" style="border: 0px" class="w-full text-xs md:text-sm p-2 h-20" id="description" placeholder="Description"></textarea>
                </div>

                <!-- Manual date -->
                <div class="border border-slate-200 rounded-md">
                    <input type="date" step="any" v-model="manualDate" style="border: 0px" class="w-full text-xs md:text-sm p-2" id="date" placeholder="Date"></input>
                </div>

            
                <!-- Facilitator -->
                 <!-- Educators Select -->
                  <div class="flex flex-row items-center justify-between mb-1.5 border border-slate-200 rounded-lg">        
                    <!-- Select -->
                    <div class="relative w-[100%] md:w-[100%]">
                        <select v-model="selectedEducator" id="educatorsSelect" ref="educatorsSelect" data-hs-select='{
                                "placeholder": "Facilitator",
                                "toggleTag": "<button type=\"button\"></button>",
                                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm font-thin text-slate-500 focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                                "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                              }' class="hidden">
                          <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
                        </select>
                          <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                          </div>
                    </div>

                  </div>  
                  <!-- End Select -->
                  
                <!-- Loading message -->
                <div v-if="isLoading" class="text-green-500">Please wait until this is done</div>
                <div v-if="backgroundProcessStarted" class="text-green-500">Great! you may close this page</div>
                
                <!-- Response message -->
                <div v-if="responseMessage" :class="responseMessage.includes('Photos uploaded successfully!') ? 'text-green-500' : 'text-red-500'">
                  {{ responseMessage }}
                </div>
                <!-- Submit button -->
                <div class="flex felx-row justify-end">
                    <button class="w-1/4 text-xs md:text-s font-bold rounded py-2.5 px-2 text-white bg-black">
                        {{ isLoading ? 'Processing...' : 'Submit' }}
                    </button>
                </div>
            </div>
         </div>
       </form>     
         
        <!-- Divider for aesthetic purposes, shown only on larger screens -->
        <div class="hidden md:block self-center font-bold text-xl">Or</div>

        <!-- Upload to Existing Album card -->
        <div :class="[showExistingAlbumForm ? 'block' : 'hidden', 'md:block']" @submit.prevent="">   
          <div class="flex flex-row p-5 gap-4 mt-4">
            <div>
              
              <div class="flex flex-col gap-2 border border-slate-200 p-2 h-[] w-[] rounded-md">
                <span class="text-black font-semibold">Add Photos to an Existing Album</span>
               
                <!-- Search -->
                <div class="border border-slate-200 rounded-full">
                    <input type="text" v-model="searchQuery" placeholder="Search by tags or date..." style="border: none; width: 100%;" class="rounded-full">
                </div>

                <!-- Album Card with images and data-->
                <!-- Using slice(0, 2) to get only the first two items from existingAlbumsData -->
                <div  v-for="(key, index) in Object.keys(filteredAlbums).slice(0, 2)" :key="index"
                  
                  class="flex flex-row border border-slate-200 p-2 w-[100%] md:w-auto md:max-w-[20.5rem] rounded-xl m-2"
                  :class="{ 'ring-2 ring-blue-500': selectedAlbumId === filteredAlbums[key].week }"                  
                  @click="handleAlbumClick(filteredAlbums[key])">
                  
                  <!-- Images -->
                  <div class="flex flex-col border-slate-200 rounded-md items-cente pl-[25px] md:p-2">
                    <div class="flex flex-wrap relative min-w-[7rem] items-center">
                      <!-- Render the images if present -->
                      <img v-if="filteredAlbums[key].first_three_images[0]" :src="filteredAlbums[key].first_three_images[0].thumbnail_url"
                        class="rounded-md" style="height: 100px !important;" width="100" />
                    </div>
                    
                  </div>

                  <!--Thumbnail data container  -->
                  <div> 
                    <!-- Date -->
                    <div class="font-medium m-2 text-md">{{ formatWeekRange(filteredAlbums[key].week) }}</div>
                    
                    <!-- Image count -->
                    <div class="text-slate-500 font-medium text-xs">{{ filteredAlbums[key].image_count }} Photos</div>  
                      
                    <!-- Labels -->
                    <div class="tags-container mt-2 h-[3.5rem] overflow-auto">
                    <div v-if="filteredAlbums[key].first_three_images[0]">
                      <span v-for="(tag, tagIndex) in filteredAlbums[key].first_three_images[0].tags" :key="`tag-${tagIndex}`"
                        class="tag-badge ml-2 mb-1 inline-flex flex-wrap rounded-sm px-1 py-1 text-xs font-medium text-black"
                        style="background-color: #FDD835;">
                        {{ tag.name }}
                      </span>
                    </div>
                  
                  </div>

                  </div> 
                </div>

                <!-- Next button -->
                <div class="flex felx-row justify-end mb-4">
                  <button @click="handleNextUploadPageClick" class="w-1/4 text-xs md:text-s font-bold rounded py-2.5 px-3 text-white bg-black">
                    Next
                  </button>
                </div>

            </div>

            </div>
          </div>
        </div>
       
     </div>

     <!--Next Page for Upload to Existing Album-->
     <div v-if="showNextPageForExistingUpload" class="bg-white rounded-lg shadow-md w-11/12 md:w-[63rem] h-auto md:h-[35rem] max-h-[97vh] overflow-auto flex md:flex-row flex-col relative">
   
        <!-- Closing cross -->
        <div class=" absolute top-0 right-0 mt-2 mr-2" @click="closeUploadPhotosModal">
          <svg class="md:w-[25px] w-[22px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
              stroke-linejoin="round" />
            <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>
        
          <!-- Input images Form card -->
          <form @submit.prevent="uploadPhotosToExistingAlbum" class="md:w-[100%] md:mt-4">
              <div class="flex flex-row md:p-5 gap-4 h-[100%]">
                  
                  <div class="flex flex-col gap-2 p-2 rounded max-w-[100%] md:w-[100%]">
                      <span class="text-black font-semibold">Add Photos to an Existing Album</span>
                              
                      <div class="flex flex-row w-full relative md:h-[55%]">
                        <!-- Input Imges -->
                        <input type="file" id="photoUploadToAlbum" ref="photoUploadToAlbum" multiple class="hidden" accept="image/*" @change="handleUploadToExistingFileChange" />
                        
                        <!-- Input Images div that gets rendered on large screens -->
                        <div style="background-color: #FEF3C2;border: 1px dashed #EAB308;" class="drop-area hidden md:flex text-xs md:text-s rounded py-3 px-3 text-black border border-slate-300 rounded-md h-[10rem] md:h-[215px] w-auto flex flex-col items-center justify-center"
                            @dragover.prevent="handleDragOver"
                            @dragleave.prevent="handleDragLeave"
                            @drop.prevent="handleDropForExistingAlbum"> 
                            <img src="@/assets/upload_photos_logo.png" alt="upload_photos_logo" height="44.7" width="70" style="max-width: none;">
                            <div class="font-medium text-center">
                              <span style="color: #1F2937;" class="mr-1">Drop your images here or</span>
                              <span style="color: #EAB308;" @click="triggerExistingFileInput">click to upload</span>
                            </div>
                            <span style="color: #AFAD9C;">Maximum size: 50MB ?</span>
                        </div>
                        
                        <!-- Rendering first 3 images of selected Album's Images for large screens -->
                        <div v-if="selectedAlbumData.images && selectedAlbumData.images.length > 0" class=" hidden md:flex flex-row w-3/4 overflow-hidden">
                          <img v-for="(image, index) in selectedAlbumData.images.slice(0, 3)" :key="index" :src="image" 
                              class="rounded-md ml-2 w-1/3 md:h-[215px] md:w-[215px] " style="height: ''; width: " />
                        </div>

                        <!-- Input image div that gets rendered on Phone screens -->
                        <div style="background-color: #FEF3C2;border: 1px dashed #EAB308;" class="md:hidden drop-area text-xs md:text-s rounded py-3 text-black border border-slate-300 rounded-md h-[100%] w-[31.3333%] flex flex-col items-center justify-center"                      
                            @dragover.prevent="handleDragOver"
                            @dragleave.prevent="handleDragLeave"
                            @drop.prevent="handleDropForExistingAlbum"> 
                            <img src="@/assets/upload_photos_logo.png" alt="upload_photos_logo" height="44.7" width="70" style="max-width: none;">
                            <div class="font-medium">
                              <span style="color: #EAB308;" @click="triggerExistingFileInput">Click to upload</span>
                            </div>
                            <span class="text-[8px]" style="color: #AFAD9C;">Maximum size: 50MB ?</span>
                        </div>
                        
                        <!-- Render only the first two images of selected Album's Images here for Phone screens-->
                        <div v-if="selectedAlbumData.images && selectedAlbumData.images.length > 0" class="md:hidden flex flex-row w-[80%] overflow-hidden">
                          <img v-for="(image, index) in selectedAlbumData.images.slice(0, 2)" :key="index" :src="image" 
                              class="rounded-md w-1/2 ml-[3%]" style="height: ''; width: " />
                        </div>                  

                        <!-- Semi-transparent black cover for the last image -->
                        <div v-if="selectedAlbumData.image_count>3" class="rounded absolute bg-black bg-opacity-50 top-0 right-0 w-[31.3333%] h-full md:w-[215px] md:mr-5 md:h-[215px]" style="z-index: 4;">           
                          <div class=" absolute left-[30%] top-[37%] text-white font-medium text-3xl"> +{{ selectedAlbumData.image_count-3 }}</div>
                        </div>
                      
                    </div>
                                  
                      <!-- Labels -->
                      <!-- <input v-model="this.selectedAlbumData.labels" class="border border-slate-200 rounded-md" type="text" id="selectedTags" placeholder="Labels"> -->
                      <AutoCompleteDropDown :initialTags="this.selectedAlbumData.labels" @update-tags="handleUpdateTagsInExistingAlbum"></AutoCompleteDropDown>

                      
                      <!-- Description -->
                      <div class="border border-slate-200 rounded-md">
                          <textarea v-model="this.selectedAlbumData.description" style="border: 0px" class="w-full text-xs md:text-sm p-2 h-20" id="selectedDescription" placeholder="Description"></textarea>
                      </div>

                      <!-- Facilitator -->
                      <!-- Educators Select -->
                      <div class="flex flex-row items-center justify-between mb-1.5 border border-slate-200 rounded-lg">        
                          <!-- Select -->
                          <div class="relative w-[100%] md:w-[100%]">
                              <select v-model="existingSelectedEducator" id="existingEducatorsSelect" ref="existingEducatorsSelect" data-hs-select='{
                                      "placeholder": "Facilitator",
                                      "toggleTag": "<button type=\"button\"></button>",
                                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-2 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm font-thin text-slate-500 focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                                      "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                                    }' class="hidden">
                                <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.name">{{ educator.educator_name }}</option>
                              </select>
                                <div class="absolute top-1/2 end-3 -translate-y-1/2">
                                  <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="m7 15 5 5 5-5"/><path d="m7 9 5-5 5 5"/></svg>
                                </div>
                          </div>

                        </div>  
                        <!-- End Select -->

                      <!-- Loading message -->
                      <div v-if="isLoading" class="text-green-500">Please wait until this is done</div>
                      <div v-if="backgroundProcessStarted" class="text-green-500">Great! you may close this page</div>
                      
                      <!-- Response message -->
                      <div v-if="responseMessage" :class="responseMessage.includes('Photos uploaded successfully!') ? 'text-green-500' : 'text-red-500'">
                        {{ responseMessage }}
                      </div>

                      <!-- Submit button -->
                      <div class="flex felx-row justify-end">
                          <button class="w-1/4 md:w-[10%] text-xs md:text-s font-bold rounded py-2.5 px-2 md:py-3 text-white bg-black">
                            {{ isLoading ? 'Uploading...' : 'Save' }}
                          </button>
                      </div>
                  </div>
              </div>
          </form> 
     </div>
  </div>
</template>


<script>
import moment from 'moment';
import AutoCompleteDropDown from './AutoCompleteDropDown.vue';



export default {
  inject: ["$call"], // function provided by doppio library 
  components:{
    AutoCompleteDropDown
  },
  props: {
    existingAlbumsData: {
      type: Object,
      required: true,
    },
    optionsResponse: {
    type: Object,
    default: () => ({}), // Provide a default empty object
    required: true,
  },
  toggleUploadStatus: {
      type: Function,
      required: true
    }
  },
  data() {
    return {   
      selectedFiles: [], // Property to hold the uploaded images
      searchQuery: '',
      labels: '',  // For storing input from labels field
      description: '',  // For storing input from description field
      date: '',
      selectedAlbumData: {}, // For storing the data of selected existing album
      showUploadPhotosForms: true,
      showNewAlbumForm: true,
      showExistingAlbumForm: false,
      showNextPageForExistingUpload: false,
      showUploadPhotosModal: true,
      selectedAlbumId: null,
      selectedFilesForExistingAlbum: [],
      selectedTags: [], // to store selected tags from AutoCompleteDropDown
      selectedTagsForExistingAlbum: [], // to store selected tags from AutoCompleteDropDown
      educatorsOptions: [],
      isLoading: false,
      backgroundProcessStarted: false,
      responseMessage: '',
    };
  },
  mounted() {
    console.log('OptionsResponse prop recieved in upload Photos Form:', this.optionsResponse);
    this.assignOptionsData()
  },
  computed: {
   filteredAlbums() {
    console.log("Filter album called, this is existing album data");
    if (!this.searchQuery.trim()) {
      return this.existingAlbumsData; // Return all albums if search query is empty
    }
    const currentYear = new Date().getFullYear(); // Assuming the current year for the search query
    let searchDate = moment(this.searchQuery.trim() + ' ' + currentYear, "DD MMM YYYY");
    const queryLower = this.searchQuery.toLowerCase();

    return this.existingAlbumsData.filter((album) => {
      // Check if any tag matches the search query
      const tagMatch = album.first_three_images.some((image) =>
        image.tags.some((tag) => tag.name.toLowerCase().includes(queryLower))
      );

      // String-based date match: Check if the date range string includes the search query
      const dateStringMatch = album.date_range.toLowerCase().includes(queryLower);

      let specificDateMatch = false; // Initialize specificDateMatch as false

      // Specific date match: If searchDate is valid, check if it falls within the album's date range
      if (searchDate.isValid()) {
        const [startDateStr, endDateStr] = album.date_range.split(' - ').map((dateStr) => dateStr + ' ' + currentYear);
        const startDate = moment(startDateStr, "Do MMM YYYY");
        const endDate = moment(endDateStr, "Do MMM YYYY");
        specificDateMatch = searchDate.isBetween(startDate, endDate, 'day', '[]');
      }

      // Return albums that match either tag or date condition
      return tagMatch || dateStringMatch || specificDateMatch;
    });
  },
  
},
  methods: {
  triggerFileInput() {
    // Trigger the click event on the hidden file input
    this.$refs.photoUpload.click();
  },
  triggerExistingFileInput() {
    // Trigger the click event on the hidden file input
    this.$refs.photoUploadToAlbum.click();
  },
  handleUploadToExistingFileChange(event) {
    // Access the files from the input
    let files = event.target.files;
    // Update the selectedFiles data property
    this.selectedFilesForExistingAlbum = files;
    console.log("handleFileChange called", this.selectedFilesForExistingAlbum)
  },
  handleFileChange(event) {
    // Access the files from the input
    let files = event.target.files;
    // Update the selectedFiles data property
    this.selectedFiles = files;
    console.log("handleFileChange called", this.selectedFiles)
  },
  handleSubmit() {
    // Trigger file upload when form is submitted
    this.handleFileUpload();
  },
  handleFileUpload(event) {
    // Store the selected files in the component's data
    console.log("these are selected file to upload",this.selectedFiles)
    // Call the upload function
    this.uploadPhotos();
  },
  handleUpdateTags(tags) {
      this.selectedTags = tags;
  },
  handleUpdateTagsInExistingAlbum(tags) {
      this.selectedTagsForExistingAlbum = tags;
  },
  handleAlbumClick(albumData) {
    this.selectedAlbumData = {
      images:albumData.first_three_images.map(image=>image.timeline_url),
      labels: this.uniqueTags(albumData).map(tag => tag.name),
      description: albumData.first_three_images[0]?.description,
      image_count: albumData.image_count
    };
    console.log("This is selected albums data----------------------------",this.selectedAlbumData)
     // Update the selectedAlbumIndex with the index of the clicked album
    this.selectedAlbumId = albumData.week;
    console.log("This is selected element id",this.selectedAlbumId)
  },
  uniqueTags(weekInfo) {
        // Flatten the array of tags from each image into a single array
        const allTags = weekInfo.first_three_images.flatMap(image => image.tags);
        console.log("These are all the tags for main page",this.allTags)
        // Use a Map to filter out unique tags by some unique property, e.g., tag.name
        const uniqueTagsMap = new Map();

        // Iterate through all tags and add them to the Map, 
        // this automatically ensures uniqueness since Map keys are unique
        allTags.forEach(tag => {
        if (!uniqueTagsMap.has(tag.name)) {
            uniqueTagsMap.set(tag.name, tag);
        }
        });
        console.log("these are unique tags",Array.from(uniqueTagsMap.values()))

        // Convert the Map values back into an array and return
        return Array.from(uniqueTagsMap.values());
      },
  handleNextUploadPageClick(){
    if(Object.keys(this.selectedAlbumData).length > 0){
    this.showUploadPhotosForms =false;
    this.showNextPageForExistingUpload = true;
    setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside Next page of upload images to Existing albums form after 100ms delay");
          }, 100)
    } 
    else{
      alert("Select an album please")
    }
  },
  closeUploadPhotosModal(){
    this.$emit('close-modal');
  },
  getSelectedValues(ref) {
    
    const selectElement = this.$refs[ref];
    if (selectElement.multiple) {
      // Handle multi-select
      const selectedOptions = selectElement.selectedOptions;
      return Array.from(selectedOptions).map(option => option.value);
    } else {
      // Handle single-select
      console.log ("Returning value for #####",selectElement.value)
      return selectElement.value;
    }
  },
  async uploadPhotos() {
  if (this.selectedFiles.length > 0) {
    this.isLoading = true;
    this.responseMessage = '';
    console.log("Vue: Starting the upload process.");

    const tagsCsv = this.selectedTags.join(',');
    const selectedEducator = this.getSelectedValues('educatorsSelect');
    console.log("Vue: Tags and educator selected:", tagsCsv, selectedEducator);

    if (window.Worker) {
      console.log("Vue: Creating Web Worker.");

      
      var worker = new Worker(new URL('./upload_worker.js', import.meta.url));
      worker.postMessage({
        files: Array.from(this.selectedFiles), // Convert FileList to Array, as forEach in worker js was throwing error
        tagsCsv: tagsCsv,
        description: this.description,
        manual_date: this.manualDate,
        educator: selectedEducator,
        csrfToken: window.csrf_token
      });
      worker.onmessage = (event) => {
          console.log("Vue: Received message from worker.", event);
          
          if (event.data.type === 'progress') {
            this.toggleUploadStatus({ visible: true, progress: event.data.percentage, totalFiles: event.data.totalFiles });
            
        } else if (event.data.type === 'success') {
            // console.log('Upload complete:', event.data.data);
            this.toggleUploadStatus({ visible: true, message: 'Upload successful: ', details: event.data });
           
        } else if (event.data.type === 'error') {
            // console.error('Upload failed:', event.data.error);
            this.toggleUploadStatus({ visible: true, message: 'Error uploading: ', details: event.data });
            
        }

          this.isLoading = false;
          this.backgroundProcessStarted = true;
          
        };

      } else {
        console.log("Vue: Web Workers are not supported in this browser.");
        this.responseMessage = 'Web Workers are not supported in your browser.';
      }
    }
    else {
        console.log("Vue: No files selected for upload.");
        alert("No files selected for upload")
        this.responseMessage = 'Please select files to upload.';
      }
  },

  async uploadPhotosToExistingAlbum() {
    if (this.selectedFilesForExistingAlbum.length > 0) {
        this.isLoading = true;
        this.responseMessage = '';
        console.log("Vue: Starting the upload process for existing album.");

        const tagsCsv = this.selectedTagsForExistingAlbum.join(',');
        const selectedEducator = this.getSelectedValues('existingEducatorsSelect');
        console.log("Vue: Tags and educator selected for existing album:", tagsCsv, selectedEducator);

        if (window.Worker) {
            console.log("Vue: Creating Web Worker for existing album upload.");
            
            var worker = new Worker(new URL('./upload_worker.js', import.meta.url));
            worker.postMessage({
                files: Array.from(this.selectedFilesForExistingAlbum),
                tagsCsv: tagsCsv,
                description: this.selectedAlbumData.description, // Assuming `selectedAlbumData.description` is correct
                educator: selectedEducator,
                csrfToken: window.csrf_token
            });
            worker.onmessage = (event) => {
                if (event.data.type === 'progress') {
                    this.toggleUploadStatus({ visible: true, progress: event.data.percentage, totalFiles: event.data.totalFiles });
                } else if (event.data.type === 'success') {
                    this.toggleUploadStatus({ visible: true, message: 'Upload successful: ', details: event.data });
                } else if (event.data.type === 'error') {
                    this.toggleUploadStatus({ visible: true, message: 'Error uploading: ', details: event.data });
                }

                this.isLoading = false;
                this.backgroundProcessStarted = true;
            };

          } else {
              console.log("Vue: Web Workers are not supported in this browser.");
              this.responseMessage = 'Web Workers are not supported in your browser.';
          }
      } else {
          console.log("No files selected for upload to existing album.");
          alert("No files selected for upload")
          this.responseMessage = 'Please select files to upload to existing album.';
      }
  },
  
  formatWeekRange(weekString) {
      if (!weekString) {
        return '';
      }
      const [year, weekNumber] = weekString.split('-');
      if (!year || !weekNumber) {
        return ' Undefined';
      }

      // Check each step to isolate the issue
      let date = moment().year(year);
      date = date.week(weekNumber);
      const startOfWeek = date.startOf('isoWeek');
      const endOfWeek = moment(startOfWeek).endOf('isoWeek');

      const formattedStart = startOfWeek.format('Do');
      const formattedEnd = endOfWeek.format('Do MMMM');

      return `${formattedStart}-${formattedEnd}`;
    },
    async assignOptionsData() {
      if (!this.optionsResponse || !this.optionsResponse.educators) {
    console.error("optionsResponse or educators property is not defined");
    return;
  }
      try {
          console.log("assign options data called in new Event form");
          const allowedNames = ['Hetvi','Kshama','Neha', 'Nikita','Priyanka', 'Ruchika', 'Sai'];
          this.educatorsOptions = this.optionsResponse.educators
            .filter(educator => allowedNames.includes(educator.educator_name))
            .sort((a, b) => allowedNames.indexOf(a.educator_name) - allowedNames.indexOf(b.educator_name));

          setTimeout(() => {
            window.HSStaticMethods.autoInit(); //Preline is initialised
          console.log("Preline Initialise inside upload images to new album form after 100ms delay");
          }, 100)
        
      } 
      catch (error) {
        console.error('Error assigning data:', error);
      }
    },
  handleDragOver(event) {
    // Optional: Add any visual cues for drag over
    event.preventDefault();  // Necessary to allow the drop
    event.target.classList.add('dragover'); // Add the dragover class
    // console.log("this is recieved prop data",this.existingAlbumsData)

  },
  handleDragLeave(event) {
    event.target.classList.remove('dragover'); // Remove the dragover class to revert visual cue
  },
  handleDrop(event) {
    let dt = event.dataTransfer;
    let files = dt.files;
    console.log("Handle drag and drop called, these are dropped files",files)
    // Set the files to the file input
    this.selectedFiles = files;
    // this.handleFileUpload({ target: { files: files } }); // Re-use the file upload handler
    // event.target.classList.remove('dragover');
  },
  handleDropForExistingAlbum(event) {
    let dt = event.dataTransfer;
    let files = dt.files;
    console.log("Handle drop for existing album called, these are dropped files",files)
    // Set the files to the file input
    this.selectedFilesForExistingAlbum = files;
    // this.handleFileUpload({ target: { files: files } }); // Re-use the file upload handler
    // event.target.classList.remove('dragover');
  },
  toggleNewAlbumForm() {
      this.showNewAlbumForm = true
      this.showExistingAlbumForm = false
    },
    toggleExistingAlbumForm() {
      this.showExistingAlbumForm = true
      this.showNewAlbumForm = false
    },


}


};
</script>

<style>
.dragover {
  background-color: #e0e0e0;
  border: 2px dashed #000;
  opacity: 0.7;
  /* Any other styles for the drag over state */
}

</style>    