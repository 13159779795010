<template>
  <div class="relative size-64">
    <svg class="size-full -rotate-90" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <!-- Background Circle -->
      <circle cx="30" cy="30" r="16" fill="none" class="stroke-current text-[#FAF9F4] 
        dark:text-neutral-700" stroke-width="8"></circle>
      <!-- Progress Circle -->
      <circle cx="30" cy="30" r="16" fill="none" class="stroke-current text-[#FDD835] 
        dark:text-blue-500" stroke-width="8" stroke-dasharray="100" :stroke-dashoffset="strokeOffset" 
        stroke-linecap="butt"></circle>
    </svg>

    <div class="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2 flex flex-col">
      <span class="text-center text-lg font-semibold text-black dark:text-blue-500">
        <!-- {{ props.count }} / {{ props.totalCount }} -->
        {{ props.count }}
      </span>
      <span class="text-[0.5rem] font-medium text-center w-20">
        <span v-if="props.observationType==='child'">Individual </span>
        <span v-if="props.observationType==='activity'">Group/Activity </span>
        <span v-if="props.observationType==='weekly'">Weekly Child </span>
        Observations 
        added this week
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps([
  'observationType', 'totalCount', 'count'
])

const strokeOffset = computed(() => {
  if (!props.count || !props.totalCount) return 100;
  return 100 - ((props.count / props.totalCount) * 100);
})
</script>