<template>


  <div class="w-full h-full overflow-auto">
      <div class="text-2xl md:text-4xl mt-6 ml-6 md:ml-8">Photo Essays</div>
      <!-- <div class="mt-4 ml-6 mb-6 text-sm md:text-lg">
        Please upload photos from the day/week here. For any field trips or special events please do add labels.
      </div> -->

      <!-- Tool bar -->
      <div class="flex flex-col md:flex-row mt-4 ml-5 md:ml-7 lg:ml-5 mr-5 md:mr-0 md:mb-6 justify-center md:justify-start">
        
        
        <!-- Search -->
        <!-- <div class="flex flex-row items-center mb-4 md:mb-0">
          <input class="w-full border border-slate-300 rounded-full px-4 py-3 text-xs md:text-sm align-middle" type="text" placeholder="Search">
        </div>  -->

        <!-- Filters and add Photos/videos button container -->
        <div class="flex flex-wrap items-start w-full md:w-[100%] mb-4 md:mb-0 z-20" :class="this.userRoleView == 'System Manager' || this.userRoleView =='Educator' ? 'lg:w-[80%]': 'lg:w-[70%]'">

          <!-- Students Filter-->
          <div v-if="optionsResponse" class="md:order-1 order-4 border w-[40%] md:w-[18%] rounded-[8px] self-start ml-1.5 mt-2 md:m-auto" :class="this.userRoleView == 'System Manager' || this.userRoleView =='Educator' ? 'lg:w-[17%]': 'lg:w-[20%]'" style="border-color: rgba(212, 215, 221, 1);"> 
            <div class="relative">
              <select v-model="preSelectedStudents" id="studentsSelect" ref="studentsSelect" multiple :data-hs-select="JSON.stringify(studentSelectConfig)"  class="hidden">
                <option value="">Select a Student</option>
                <option v-if="this.userRoleView && ( this.userRoleView == 'System Manager' || this.userRoleView =='Educator' )" v-for="option in optionsResponse.students":key="option.name" :value="option.name">
                  {{ option.student_name }}
                </option>
                <option v-if="this.userRoleView && this.userRoleView== 'Parent'" v-for="option in optionsResponse.children_student_info":key="option.name" :value="option.name">
                  {{ option.student_name }}
                </option>
              </select>
              <div class="absolute top-1/2 end-3 -translate-y-1/2">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path d="m7 15 5 5 5-5" />
                  <path d="m7 9 5-5 5 5" />
                </svg>
              </div>
            </div>
          </div> 

          <!-- Month Filter -->
          <div class="md:order-2 order-1 border w-[30%] md:w-[18%] rounded-[8px] m-auto" :class="this.userRoleView == 'System Manager' || this.userRoleView =='Educator' ? 'lg:w-[17%]': 'lg:w-[20%]'" style="border-color: rgba(212, 215, 221, 1);">
            
            <div class="relative">

              <select v-model="selectedMonth" id="monthsSelect" ref="monthsSelect" multiple data-hs-select='{
              
              "placeholder": "Month",
              "toggleTag": "<button type=\"button\"></button>",
              "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
              "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
              "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
              "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
            }' class="hidden">
                  <option value="">Select a Month</option>
                  <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>

              </select>
              <div class="absolute top-1/2 end-3 -translate-y-1/2">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path d="m7 15 5 5 5-5" />
                  <path d="m7 9 5-5 5 5" />
                </svg>
              </div>
            </div>
            <!-- End Select -->
          </div>

          <!-- Week Filter-->
          <div class="md:order-3 order-2 border w-[30%] md:w-[18%] rounded-[8px] m-auto" :class="this.userRoleView == 'System Manager' || this.userRoleView =='Educator' ? 'lg:w-[17%]': 'lg:w-[20%]'" style="border-color: rgba(212, 215, 221, 1);"> 
            <div class="relative">
              <select id="weeksSelect" :key="selectKey" ref="weeksSelect" multiple data-hs-select='{
              "placeholder": "Week",
              "toggleTag": "<button type=\"button\"></button>",
              "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
              "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
              "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
              "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
            }' class="hidden">
                <option value="">Select a Week</option>
                <option v-for="option in weekFilterOptions":key="option.weekNumber" :value="option.weekNumber">
                  {{ option.dateRange }}
                </option>
              </select>
              <div class="absolute top-1/2 end-3 -translate-y-1/2">
                <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                  width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round">
                  <path d="m7 15 5 5 5-5" />
                  <path d="m7 9 5-5 5 5" />
                </svg>
              </div>
            </div>
            <!-- End Select -->
          </div>

          <!-- Year Filter-->
          <div class="md:order-4 order-3 border w-[30%] md:w-[18%] rounded-[8px] m-auto hs-tooltip inline-block [--trigger:click] [--placement:right]" :class="this.userRoleView == 'System Manager' || this.userRoleView =='Educator' ? 'lg:w-[17%]': 'lg:w-[20%]'" style="border-color: rgba(212, 215, 221, 1);"> 
                <div class="relative hs-tooltip-toggle">
                  <select id="educatorsSelect" ref="educatorsSelect" multiple data-hs-select='{
                  "placeholder": "2024",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-none before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "drpdwnCls": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                }' class="hidden">
                    <option v-for="educator in educatorsOptions" :key="educator.name" :value="educator.educator_name">{{
                educator.educator_name }}</option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
                <!-- End Select -->
                <span class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-3 px-4 bg-white border text-sm text-gray-600 rounded-lg shadow-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-400" role="tooltip">
                  Year filter coming soon! 
                </span>
          </div>

          <!-- Add Photos/videos button container -->
          <div v-if="this.userRoleView && ( this.userRoleView == 'System Manager' || this.userRoleView =='Educator' )" class="order-5 mt-2 ml-4 md:m-auto w-[50%] md:w-auto flex justify-end ">
            <!-- Preline dropdown -->
            <div class="hs-dropdown relative inline-flex">
              
              <!-- Button -->
              <button id="hs-dropdown-default" type="button" class="hs-dropdown-toggle bg-black text-white text-[12px] md:text-[15px] py-3 px-4 rounded-md" aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">Add Photos/Videos</button>
           

              <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full" role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-default">
                <div class="p-1 space-y-0.5">
                  <div @click="handleCreateNewAlbum" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                    Create New Album
                  </div>
                  <div @click="handleAddToExistingAlbum" class="flex items-center cursor-pointer gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:hover:text-neutral-300 dark:focus:bg-neutral-700" href="#">
                    Add to Existing Album
                  </div>
                </div>
              </div>
            </div>
          </div>

         
        </div>
        
      </div>

      <!-- Upload Videos Form Component -->
      <template v-if="showUploadVideoFormComponent && optionsResponse">
        <UploadVideosForm :optionsResponse="optionsResponse" @close-modal="handleVideoUploadModalClose" :existing-albums-data="imagesData" :toggleUploadStatus="toggleUploadStatus" :defaultTab="defaultTab"></UploadVideosForm>
      </template>

        <!-- Thumbnail card,Timeline vue, upload photo components -->
        <div class="flex flex-start flex-wrap overflow-x-auto h-[74.2%] md:h-[70vh] px-3 md:px-5 custom-scrollbar">

          <!-- Upload Photos Form Component -->
          <template v-if="showUploadFormComponent && optionsResponse">
            <UploadPhotosForm :options-response="optionsResponse" :existing-albums-data="imagesData" @close-modal="handleCloseModal" :toggleUploadStatus="toggleUploadStatus"></UploadPhotosForm>
          </template> 

          
          <!-- Spinner logo -->
          <div v-if="photosloading" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50">
            
            <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
              <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
              <span class="sr-only">Loading...</span>
            </div>

            <!--Preline Spinner logo -->
              <!-- <div style="color: #FFCE31" class="animate-spin inline-block size-16 border-[8px] border-current border-t-transparent text-yellow-600 rounded-full" role="status" aria-label="loading"> 
                <span class="sr-only">Loading...</span>
              </div> -->

            <!-- <div class="absolute text-center mt-36 font-normal text-lg">This page currently takes about 15-20 seconds to load</div> -->
          </div>

          <!-- Thumbnail Card -->
          <template v-for="(weekData, index) in filteredImagesData" :key="index"> 
            <div v-if="weekData.first_three_images[0]" @click="handleImageClick(weekData.first_three_images,weekData)" class="flex flex-col border border-slate-200 p-2 w-[100%] md:w-auto md:max-w-[20.5rem] md:max-h-[20rem] rounded-3xl m-2">
              
              <!-- Date -->
              <div class="font-bold m-2 text-lg">{{ shortenDateRange(weekData.date_range) }}</div>
              
              <!-- Tags -->
              <div class="tags-container mt-2">
                <div v-if="weekData.first_three_images[0]">
                  <span v-for="(tag, tagIndex) in uniqueTags(weekData)" :key="`tag-${tagIndex}`"
                    class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-2 py-1 text-sm font-medium text-black"
                    style="background-color: #FDD835;">
                    {{ tag.name }}
                  </span>
                </div>
              </div>

              <!-- Images -->
              <div class="flex flex-col border-slate-200 rounded-md items-cente pl-[25px] md:p-2 cursor-pointer">
                <div class="flex flex-wrap relative min-h-[10rem] min-w-[15rem] md:min-w-[18rem] items-center">
                  <!-- Render the images if present -->
                  <img v-if="weekData.first_three_images[0]" :src="weekData.first_three_images[0].thumbnail_url"
                    class="rounded-3xl absolute left-[0px] md:left-[0px]" style="z-index: 1; height: 125px !important;"
                    width="125"/>

                  <img v-if="weekData.first_three_images[1]" :src="weekData.first_three_images[1].thumbnail_url"
                    class="rounded-3xl absolute left-[25%] md:left-[85px]"
                    style="z-index: 2; outline: 8px solid white; height: 125px !important;" width="125"/>

                  <img v-if="weekData.first_three_images[2]" :src="weekData.first_three_images[2].thumbnail_url"
                    class="rounded-3xl absolute left-[50%] md:left-[170px]"
                    style="z-index: 3; outline: 8px solid white; height: 125px !important;" width="125"/>

                  <!-- Semi-transparent black cover for the 3rd image -->
                  <div v-if=" weekData.image_count > 3"
                    class="rounded-3xl absolute bg-black bg-opacity-50 left-[50%] md:left-[170px]"
                    style="z-index: 4; height: 125px; width: 125px;"></div>

                  <!--Image count on top of black cover  -->
                  <div v-if="weekData.image_count > 3" class="absolute z-10 text-white text-5xl"
                    style="left: calc(180px + 0px ); top: 57px;"> +{{ weekData.image_count-3 }}</div>  
                </div>
              
                <!-- Description -->
                <!-- <div class="text-slate-600" >{{ weekData.first_three_images[0].description }} </div>
                -->
              </div>

            </div>
          </template> 
          

          <!-- Timeline view component -->
          <template v-if="renderTimelineView">
            <TimelineView :weeks-images-data="weeksImagesPropData" :existing-albums-data="imagesData" :selected-students="this.selectedStudents" @close-modal="handleCloseTimelineModal"></TimelineView>
          </template>

        </div>

        <!-- Pagination Controls -->
        <!-- <div class="pagination-controls flex justify-center mt-4">
            <button @click="prevPage" :disabled="pageNumber === 1" class="px-4 py-2 border rounded mr-2">
                Previous
            </button>
            <span v-if="totalWeeksCount" class="px-4 py-2">{{ pageNumber+'/'+totalWeeksCount/6 }}</span>
            <button @click="nextPage" :disabled="!hasMoreData" class="px-4 py-2 border rounded ml-2">
                Next
            </button>
        </div> -->
  </div>
</template>

<script>
import UploadPhotosForm from '../components/photoEssays/UploadPhotosForm.vue';
import TimelineView from '../components/photoEssays/TimelineView.vue';
import moment from 'moment';
import 'preline/preline';
import { toRaw } from 'vue';
import uppyUploader from '../components/videoUpload/uppyUploader.vue';
import UploadVideosForm from '../components/videoUpload/UploadVideosForm.vue';


export default {
  inject: ["$call"], // function provided by doppio library 
  components: {
    UploadPhotosForm,
    TimelineView, 
    uppyUploader,
    UploadVideosForm,
  },
  props: {
    optionsResponse: {
      type: Object,
      required: true,
    },
    toggleUploadStatus: {
      type: Function,
      required: true
    },
    userDetails: {
      type: Object,
      required: true,
    },
    userRoleView: {
      type: String,
      required: true,
    }
  }, 
  data() {
    return {
      // Property to hold the selected files
      firstThreeMonthsData: [], // Cache the first three months data
      selectedFiles: [],
      imagesData: {},
      filteredImagesData:{},
      weekFilterOptions:[],
      showUploadFormComponent:false,
      weeksImagesPropData:{},
      renderTimelineView: false,
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      selectKey: 0, //For forcefully re-rendering the week filter
      selectedStudents: null,
      selectedCohorts: null,
      photosloading: true,
      dateRanges: null,
      // Pagination controls 
      pageNumber: 1,
      pageSize: 6,
      hasMoreData: true,
      totalWeeksCount: 0,
      preSelectedStudents:[],

      showUploadVideoFormComponent:false,
      defaultTab: 'create-new', 
     
    };
  },
  watch: {
    optionsResponse(newVal) {
      if (newVal) {
        setTimeout(() => {
          window.HSStaticMethods.autoInit(); // Initializing custom selects

          // Initialize the select element for months
          const monthSelectElement = window.HSSelect.getInstance('#monthsSelect');
          if (monthSelectElement) {
            monthSelectElement.on('change', (value) => {
              console.log('Month select value changed:', value);
              // Update the selectedMonths variable with the new value
              this.selectedMonths = value ? [...value] : [];
              console.log(`after update`)
              console.log(this.selectedMonths);
              this.filterByMonth(value); // Directly call filterByMonth method
            });
          } else {
            console.log('MonthSelect instance not found');
          }

          // Initialize the select element for students filter
          const studentSelectElement = window.HSSelect.getInstance('#studentsSelect');
          if (studentSelectElement) {
            studentSelectElement.on('change', (value) => {
              console.log('Student select value changed:', value);
              // Update the selectedMonths variable with the new value
              this.selectedStudents = value ? [...value] : [];
              console.log("These are selected months",this.selectedMonths);
             this.filterByStudent(value)
            });
          } else {
            console.log('MonthSelect instance not found');
          }

        }, 100);
        
      }
    }
  },
  mounted(){
    this.selectedMonths = []; 
    this.fetchWeeklyImagesOnmouting(); // Assuming this is where you fetch initial data
    setTimeout(() => {
          window.HSStaticMethods.autoInit(); // Initializing custom selects

          // Initialize the select element for months
          const monthSelectElement = window.HSSelect.getInstance('#monthsSelect');
          if (monthSelectElement) {
            monthSelectElement.on('change', (value) => {
              console.log('Month select value changed:', value);
              // Update the selectedMonths variable with the new value
              this.selectedMonths = value ? [...value] : [];
              console.log(`after update`)
              console.log(this.selectedMonths);
              this.filterByMonth(value); // Directly call filterByMonth method
            });
          } else {
            console.log('MonthSelect instance not found');
          }

          // Initialize the select element for students filter
          const studentSelectElement = window.HSSelect.getInstance('#studentsSelect');
          if (studentSelectElement) {
            studentSelectElement.on('change', (value) => {
              console.log('Student select value changed:', value);
              // Update the selectedMonths variable with the new value
              this.selectedStudents = value ? [...value] : [];
              this.filterByStudent(value)
            });
          } else {
            console.log('MonthSelect instance not found');
          }

        }, 100);
  },

  computed: {
    studentSelectConfig() {
      return {
        placeholder: "Learners",
        hasSearch: this.userDetails.is_system_manager || this.userDetails.user_roles.includes('Educator'),
        toggleTag: "<button type=\"button\"></button>",
        toggleClasses: "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 focus:outline-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
        dropdownClasses: "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
        optionClasses: "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
        optionTemplate: "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
      };
    },
     
   }, 
  methods: {

    nextPage() {
      this.pageNumber.value += 1;
      // fetchWorksheets();
      fetchWeeklyImages()
     },

    prevPage() {
      if (this.pageNumber.value > 1) {
          this.pageNumber.value -= 1;
          fetchWeeklyImages()
          
       }
     },
    shortenDateRange(dateRange) {
        const [start, end] = dateRange.split(' - ');
        const startDate = moment(start, 'MMMM DD, YYYY');
        const endDate = moment(end, 'MMMM DD, YYYY');
        if (startDate.month() === endDate.month()) {
            return `${startDate.format('DD')} - ${endDate.format('DD MMM YYYY')}`;
          } else {
            return `${startDate.format('DD MMM')} - ${endDate.format('DD MMM YYYY')}`;
          }
      },
    async fetchWeeklyImages() {
      try {
        
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_weekly_images_new', {
          
        });
        
        const weeklyImagesData = response.data

        this.imagesData = [...weeklyImagesData]
        this.filteredImagesData = [...weeklyImagesData]

        this.photosloading = false;

        this.totalWeeksCount = response.pagination.total_weeks;
        // this.hasMoreData = response.pagination.length === this.pageSize

      } catch (error) {
        console.error("Error fetching weekly images:", error);
        // Handle error appropriately, maybe set an error state or show a message
      }
    },
    getImageUrl(relativeUrl) {
      const sanitizedUrl = relativeUrl.replace(/^\/private/, '');
      
      // Construct the base URL dynamically
      const baseUrl = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

      return `${baseUrl}${sanitizedUrl}`;
    },


//FilterByStudent method to handle selected students
  async filterByStudent(selectedStudents) {
    console.log("Filter by students called with:", selectedStudents);

    // Update the selected students in data
    this.selectedStudents = selectedStudents;
   
    try {
        // Fetch data for all selected months at once
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_weekly_images_new', {
          date_ranges: this.dateRanges,
          student_ids: this.selectedStudents
        });
         console.log("This was student filter response **********************************",response)
        // Assuming the response is already in the correct format
        this.filteredImagesData = response.data;
        this.photosloading = false;

        console.log("Filtered data for selected students:", this.filteredImagesData);
      } catch (error) {
        console.error("Error fetching data for selected Students", error);
       
      }
    
     
  },


  async filterByMonth(selectedMonths) {
      console.log("Filter by month called with selected months:", selectedMonths);

      // If no months are selected, clear the filtered data
      if (!selectedMonths.length) {
        console.log("No months selected");
        this.filteredImagesData = [];
        this.dateRanges = null
        
      }

      else{
        const currentYear = new Date().getFullYear();
        this.dateRanges = selectedMonths.map(month => {
          const monthDate = new Date(`${month} 1, ${currentYear}`);
          return {
            startDate: moment(monthDate).startOf('month').format('YYYY-MM-DD'),
            endDate: moment(monthDate).endOf('month').format('YYYY-MM-DD')
          };
        });
      } 
      

      try {
        // Fetch data for all selected months at once
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_weekly_images_new', {
          date_ranges: this.dateRanges,
          student_ids: this.selectedStudents
        });
        // Assuming the response is already in the correct format
        this.filteredImagesData = response.data;

        // Update week filter options based on the selected months
        await this.updateWeekFilterOptions(selectedMonths);

        console.log("Filtered data for selected months:", this.filteredImagesData);
      } catch (error) {
        console.error("Error fetching data for selected months:", error);
        // Handle the error appropriately
      }
    },



    async filterByWeek(selectedWeeks) {
      
      this.dateRanges = selectedWeeks.map(weekString => this.getDateRangeForWeek(weekString));

      console.log("Date ranges for selected weeks:", this.dateRanges);

      try {
        // Fetch data for all selected months at once
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_weekly_images_new', {
          date_ranges: this.dateRanges,
          student_ids: this.selectedStudents
        });
         // Assuming the response is already in the correct format
        this.filteredImagesData = response.data;

        console.log("Filtered data for selected weeks:", this.filteredImagesData);
      } catch (error) {
        console.error("Error fetching data for selected months:", error);
        // Handle the error appropriately
      }

    },

    fetchWeeklyImagesOnmouting(){
      const learnersParam = this.$route.query.learners;
      if (learnersParam) {
        this.preSelectedStudents = learnersParam.split('-').map(id => id.trim());
        this.filterByStudent(this.preSelectedStudents)
      }
      else this.fetchWeeklyImages()
    },
    
    getDateRangeForWeek(weekString) {
      const [year, week] = weekString.split('-').map(Number);
      
      // Create a date for January 1st of the given year
      const januaryFirst = new Date(year, 0, 1);
      
      // Calculate the first day of the week (Monday)
      const daysToAdd = (week - 1) * 7 - januaryFirst.getDay() + 1;
      const weekStart = new Date(year, 0, 1 + daysToAdd);
      
      // Calculate the last day of the week (Sunday)
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekStart.getDate() + 6);
      
      return {
        startDate: moment(weekStart).format('YYYY-MM-DD'),
        endDate: moment(weekEnd).format('YYYY-MM-DD')
      };
    },
    
    // Method to update week filter options based on the selected month MANUALLY
    async updateWeekFilterOptions(selectedMonths) {
      console.log("Selected Months:", selectedMonths);

      // Assuming only one month can be selected at a time for simplicity
      this.weekFilterOptions = this.generateWeekFilterOptions(selectedMonths[0]);
      console.log("Week filter options:", this.weekFilterOptions);

      await this.reinitializePrelineSelect();
    },

    generateWeekFilterOptions(selectedMonth) {
      let weekFilterOptions = [];
      let currentYear = moment().year();
      let startOfMonth = moment().month(selectedMonth).startOf('month').year(currentYear);
      let startOfWeek = startOfMonth.clone().startOf('isoWeek');

      while (startOfWeek.month() === startOfMonth.month() || 
            (startOfWeek.month() === startOfMonth.clone().subtract(1, 'month').month() && 
            startOfWeek.clone().add(6, 'days').month() === startOfMonth.month())) {
        let endOfWeek = startOfWeek.clone().endOf('isoWeek');
        let weekRange = `${startOfWeek.format('MMM DD')} - ${endOfWeek.format('MMM DD, YYYY')}`;

        weekFilterOptions.push({
          weekNumber: `${currentYear}-${startOfWeek.isoWeek()}`, // ISO week number prefixed with the current year
          dateRange: weekRange,
        });

        startOfWeek.add(1, 'weeks');
      }

      return weekFilterOptions;
    },
    async reinitializePrelineSelect() {
      const selectEl = this.$refs.weeksSelect;
      if (selectEl) {
        const selectInstance = window.HSSelect.getInstance(selectEl);
        if (selectInstance && selectInstance.destroy) {
          selectInstance.destroy();
          console.log("HSSelect instance destroyed");
        }
      }

      this.selectKey++; // Increment selectKey to force re-render

      await this.$nextTick(); // Wait for Vue to finish updating the DOM

      window.HSStaticMethods.autoInit();
      console.log("Preline reinitialized in photo essay");

      await this.$nextTick(); // Wait for Preline to finish reinitializing

      const newWeekSelectInstance = window.HSSelect.getInstance(this.$refs.weeksSelect);
      if (newWeekSelectInstance) {
        newWeekSelectInstance.on('change', (value) => {
          console.log('Week select value changed:', value);
          this.filterByWeek(value); // Handle the week selection change
        });
        console.log("Event listener attached to new week select instance");
      }
    },

    formatWeekRange(weekString) {
      if (!weekString) {
        return '';
      }
      const [year, weekNumber] = weekString.split('-');
      if (!year || !weekNumber) {
        return ' Undefined';
      }

      // Check each step to isolate the issue
      let date = moment().year(year);
      date = date.week(weekNumber);
      const startOfWeek = date.startOf('isoWeek');
      const endOfWeek = moment(startOfWeek).endOf('isoWeek');

      const formattedStart = startOfWeek.format('Do');
      const formattedEnd = endOfWeek.format('Do MMMM');

       // If start month and end month are the same, return formatted start date
       if (startOfWeek.month() === endOfWeek.month()) {
        return `${formattedStart}-${formattedEnd}`;
        }
         
        else {
        // If start month and end month are different, also include end month
        return `${startOfWeek.format('DD MMMM')} - ${endOfWeek.format('Do MMMM')}`;
        }

    },
    uniqueTags(weekInfo) {
        // Flatten the array of tags from each image into a single array
        const allTags = weekInfo.first_three_images.flatMap(image => image.tags);
        const uniqueTagsMap = new Map();

        // Iterate through all tags and add them to the Map, 
        // this automatically ensures uniqueness since Map keys are unique
        allTags.forEach(tag => {
        if (!uniqueTagsMap.has(tag.name)) {
            uniqueTagsMap.set(tag.name, tag);
        }
        });
        
        // Convert the Map values back into an array and return
        return Array.from(uniqueTagsMap.values());
      },
    handleImageClick(first_three_image,weekData){
      console.log("This is week data recieved in handle image click",weekData);
      // Assigning first_three_images and weekData to weeksImagesPropData
      this.weeksImagesPropData = {
        images: first_three_image, // Array of image objects
        weekInfo: weekData // Additional week data
      };

      this.renderTimelineView = true;
      console.log("render timeline view set to true")
    },
    handleCreateNewAlbum() {
      this.defaultTab = 'create-new';
      this.showUploadVideoFormComponent = true;
    },
    handleAddToExistingAlbum() {
      this.defaultTab = 'add-to-existing'; 
      this.showUploadVideoFormComponent = true;
    },
    handleVideoUploadModalClose(){
      this.showUploadVideoFormComponent = false;
    },
    handleCloseModal(){
      this.showUploadFormComponent = false;
    },
    handleCloseTimelineModal(){
      this.renderTimelineView = false;
    }


  }


};
</script>

<style scoped>

/* Styling for loading spinner */
  .bg-conic-gradient {
    background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
   }

  .spinner-mask {
    --spinner-thickness: 3px;
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  }


/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Adjust scrollbar width */
  height: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #807f7f;
  /* Thumb color */
  border-radius: 6px;
  /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #636363;
  /* Hover state color */
}
/* To hice scrollbar on mobile view */
@media screen and (max-width: 767px) {
    /* CSS for screens smaller than 768px */
  .custom-scrollbar::-webkit-scrollbar {
    width: 0px;
    /* Adjust scrollbar width */
    height: 0px;
}
}

</style>