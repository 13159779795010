<template>
  <div class="flex flex-col lg:flex-row">
    <div class="grid grid-cols-6 size-64 rounded-xl overflow-hidden shadow-lg lg:flex-auto lg:basis-1/2">
      <div v-for="(option, index) in answerOptions" :style="{ backgroundColor: optionColors[index] }"
        :class="cn(`p-2 flex flex-col gap-1 flex-wrap basis-1/3 justify-stretch col-span-2`,
          index > 2 && 'col-span-3'
        )">
        <p v-for="child in studentsGroupedByAns[option] || []" class="text-xs font-medium">
          <!-- {{ child }} -->
          {{ findValueByName(userDetails?.school_info.students, child, 'student_name') }}
        </p>
      </div>
    </div>
    <div class="lg:flex-auto lg:basis-1/2 flex p-3 md:p-0 md:justify-center">
      <div class="space-y-1">
        <div class="flex gap-2" v-for="(option, index) in displayOptions">
          <div :style="{ backgroundColor: optionColors[index] }" :class="`size-4 shrink-0`" ></div>
          <p class="text-[0.6rem] font-medium">{{ option }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import { findValueByName } from '@/lib/utils';
import { cn } from '@/components/ui/utils.js';

const props = defineProps(['weeklyChildData'])

const userDetails = inject('userDetails')

const displayOptions = [
  "Stays engaged most of the time",
  "Sometimes gets distracted but can refocus",
  "Often needs reminders to pay attention",
  "Finds it hard to concentrate on tasks",
  "Not sure",
]

const answerOptions = [
  "Stays engaged most of the time",
  "Sometimes gets distracted but can refocus",
  "Often needs reminders to pay attention",
  "Finds it hard to concentrate on tasks",
  "Not sure",
]

const optionColors = [
  '#FFC000', '#F58F00', '#FE965C', '#EC681B', '#FFEBAE'
]

const studentsGroupedByAns = computed(() => {
  if (!props.weeklyChildData?.ongoing) return {};
  return props.weeklyChildData?.ongoing.reduce((acc, observation) => {
    if (!acc[observation.focus_ability]) {
      acc[observation.focus_ability] = [];
    }

    acc[observation.focus_ability].push(observation.student)

    return acc;
  }, {});
}) 
</script>