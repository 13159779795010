<template>
  <div v-if="userDetails?.is_system_manager" class="flex flex-col h-full max-h-[94vh] md:max-h-[91.5vh] 
    pt-6 px-2 lg:pt-8 lg:pl-8 gap-4 lg:gap-8 lg:pr-4 overflow-y-auto">
    <div class="flex">
      <div class="flex flex-col gap-4 lg:gap-8">
        <h2 class="text-3xl">Admin Controls</h2>
      </div>
    </div>
    <div>
        This is the admin controls page
    </div>
    <div>
      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium 
        rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 
        disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 
        dark:text-white dark:hover:bg-neutral-800" @click="removeCompetencies">
        Remove Old Competencies
      </button>
      <div class="border">
        <div v-if="importCountResource.data">
          <div>Imported: {{ importCountResource.data.daily.imported }}</div>
          <div>Total Rows: {{ importCountResource.data.daily.total }}</div>
        </div>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
          dark:text-white dark:hover:bg-neutral-800" @click="importChildObservations">
          Import Child Observations
        </button>
      </div>
      <form @submit.prevent="importChildRange">
        <div class="flex">
          <NumberInput label="Start Index" inputName="start" />
          <NumberInput label="End Index" inputName="end" />
        </div>
        <button type="submit" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
          dark:text-white dark:hover:bg-neutral-800">
          Import Child Observations - From Range
        </button>
      </form>
      <div class="border">
        <div v-if="importCountResource.data">
          <div>Imported: {{ importCountResource.data.activity.imported }}</div>
          <div>Total Rows: {{ importCountResource.data.activity.total }}</div>
        </div>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
          dark:text-white dark:hover:bg-neutral-800" @click="importActivityObservations">
          Import Activity Observations
        </button>
        <form @submit.prevent="importActivityRange">
          <div class="flex">
            <NumberInput label="Start Index" inputName="start" />
            <NumberInput label="End Index" inputName="end" />
          </div>
          <button type="submit" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
            dark:text-white dark:hover:bg-neutral-800" >
            Import Activity Observations - Range
          </button>
        </form>
      </div>
      <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
        rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
        disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
        dark:text-white dark:hover:bg-neutral-800" @click="backupObservations" >
        Backup to S3
      </button>
      <div class="flex flex-col gap-2">
        <div class="flex flex-col gap-2">
          <p v-if="isMapping">Creating Activity Type mappings. Please wait...</p>
          <p>{{ mappingMessage }}</p>
        </div>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
          dark:text-white dark:hover:bg-neutral-800" @click="addActivityTypes" :disabled="isMapping" >
          Add Actvity Types
        </button>
      </div>
      <div>
        <h3>Publish Drafts</h3>
        <form @submit.prevent="publishDraft" class="flex gap-4">
          <div class="max-w-sm space-y-3">
            <input type="text" class="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 
              focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 
              dark:border-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600"
              name="draft_id">
          </div>
          <button type="submit" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
            dark:text-white dark:hover:bg-neutral-800" :disabled="isPublishing" >
            Publish
          </button>
        </form>
      </div>
      <div class="flex gap-8">
        <h3>Sentry test button: </h3>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
          dark:text-white dark:hover:bg-neutral-800" @click="throwSentryError" >
          Throw error
        </button>
      </div>
      <div class="flex gap-8">
        <h3>Generate weekly summaries</h3>
        <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
          rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
          disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
          dark:text-white dark:hover:bg-neutral-800" @click="() => generateWeeklySummariesResource.fetch()" >
          Generate
        </button>
      </div>
    </div>
  </div>
  <div v-if="!userDetails?.is_system_manager" class="flex justify-center items-center h-full">
    This page is not available to you.
  </div>
</template>

<script setup>
import { createListResource, createResource } from 'frappe-ui'
import { useRouter } from 'vue-router';
import { inject, provide, ref } from 'vue';
import NumberInput from '../components/admin/NumberInput.vue';

// const router = useRouter()

const userDetails = inject('userDetails')

// const manDataResource = createResource({
//   url: '/api/method/curriculum_planner.curriculum_planner.common.user_details.api.get_user_man_data',
//   auto: true
// })

// const reloadManData = () => {
//   manDataResource.reload()
// }

const removeCompResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.doctype.daily_observations.api.remove_old_competencies',
  params: {
    mode: 'all'
  },
})

const removeCompetencies = () => {
  try {
    removeCompResource.fetch()
    console.log(removeCompResource)
  } catch (error) {
    alert(error) 
  }
}

const childObservationResourc = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.imports.observations.api.import_daily_observation_data',
})

const importChildObservations = () => {
  try {
    childObservationResourc.fetch()
    console.log(childObservationResourc)
  } catch (error) {
    alert(error) 
  }
}

const importChildRange = (e) => {
  const formData = new FormData(e.target)
  const start_index = +(formData.get("start"))
  const end_index = +(formData.get("end"))
  try {
    childObservationResourc.submit({
      mode: "range",
      start_index: start_index,
      end_index: end_index,
    })
  } catch (error) {
    alert(error)
  }
}

const activityObservationResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.imports.observations.api.import_activity_observation_data',
})

const importActivityObservations = () => {
  try {
    activityObservationResource.fetch()
    console.log(activityObservationResource)
  } catch (error) {
    alert(error) 
  }
}

const importActivityRange = (e) => {
  const formData = new FormData(e.target)
  const start_index = +(formData.get("start"))
  const end_index = +(formData.get("end"))
  try {
    activityObservationResource.submit({
      mode: "range", 
      start_index: start_index,
      end_index: end_index,
    })
  } catch (error) {
    alert(error)
  }
}

const importCountResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.imports.observations.api.get_observation_import_status',
  auto: true
})

const backupResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.backup.api.test_s3',
})

const backupObservations = () => {
  backupResource.fetch()
}

const addActivityTypeResource = createResource({
  url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.activity_type.add_activity_type',
})

const isMapping = ref(false)
const mappingMessage = ref('')

const addActivityTypes = async () => {
  isMapping.value = true;
  try {
    await addActivityTypeResource.submit()
    if (addActivityTypeResource.data.status === 'success') {
      const total = addActivityTypeResource.data.total_observations;
      const mapped = addActivityTypeResource.data.mapped_observations;
      mappingMessage.value = `Added activity type mapping for ${mapped} out of ${total} observations.`
    } else {
      mappingMessage.value = `Failed to add mapping for activity type: ${addActivityTypeResource.data.message}`
    }
  } catch (error) {
    console.error('Failed to make activity type link request')
    console.error(error)
  }
  isMapping.value = false
}

const isPublishing = ref(false)
const publishDraftResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.week_plan_draft.api.publish_draft'
})

const publishDraft = async (e) => {
  isPublishing.value = true
  const formData = new FormData(e.target)
  const draftId = formData.get('draft_id')
  console.log("Draft ID:", { id: draftId})
  if (!draftId) {
    console.log("Draft ID not present. Please save draft and try again!");
    return;
  }
  try {
    await publishDraftResource.submit({ draft_id: draftId})
    if (publishDraftResource.data.status === 'success') {
      console.log("Successfully published Draft!")
    } else {
      console.error("Failed to publish doc")
      console.error(publishDraftResource.data.message)
    }
  } catch (error) {
    console.error("Failed to make publish request!")
  }
  isPublishing.value = false
}

const throwSentryError = () => {
  myUndefinedFunction();
}

const generateWeeklySummariesResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.weekly.api.generate_ongoing_summaries'
})

console.log("weekly", generateWeeklySummariesResource)
</script>