<template>
  <Card :class="cn(`basis-1/3 gap-2 rounded flex-1 min-w-0`, 'lg:flex')">
    <template #title>
      <div class="flex justify-between items-center lg:items-stretch lg:gap-2 xl:flex-row 
        xl:items-center lg:flex-auto xl:flex-initial" ref="cardRef">
        <span class="font-medium text-sm md:text-lg lg:flex lg:flex-col xl:inline">
          Weekly Child <span>Observations</span>
        </span>
        <!-- <ParentObservationModal v-if="childModalActive" modalName="parent-form-modal" :userData="props.userData"
          :reloadFn="reloadChildModal" :reloadObservations="props.reloadObservations"> -->
        <!-- <WeeklyChildModal v-if="childModalActive" modalName="weekly-child-form-modal" 
          :userData="userDetails">
          <template #trigger>
            <button type="button" class="py-2 md:py-3 px-8 2xl:px-16 inline-flex items-center gap-x-2 text-sm
              font-semibold rounded border border-transparent bg-gray-800 text-white
              hover:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600 dark:bg-white dark:text-gray-800
              justify-center shrink-0" data-hs-overlay="#hs-weekly-child-form-modal"
              @click="currentFormNum = 2">
              Add New
            </button>
          </template>
        </WeeklyChildModal> -->
      </div>
    </template>
    <div class="flex flex-col md:h-[90%] gap-2">
      <div v-if="props.observeData?.weekly_child?.length"
        class="flex flex-col gap-2 md:gap-4 flex-auto overflow-y-auto pr-2">
        <WeeklyChildObservViewCard v-for="observation in props.observeData?.weekly_child?.slice(0,2)"
          :observation="observation" :selectObservation="selectObservation" />
      </div>
      <div v-else class="flex flex-col gap-2 md:gap-4 flex-auto items-center text-xs text-gray-500
        font-medium xl:text-sm">
        Nothing to show here. Click on the Add New button to create your first observation
      </div>
      <div v-if="props.observeData?.weekly_child?.length"
        class="flex items-center justify-start gap-8 mt-2">
        <router-link :to="`/front-portal/observations/view/weekly-child/all`" class="flex">
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex-auto flex justify-center items-center">
            View All
          </button>
        </router-link>
      </div>
      <div v-else class="flex items-center justify-start gap-8 mt-2">
        <div>
          <button type="button" class="py-2 px-8 inline-flex items-center gap-x-2 text-sm font-medium
            rounded border border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900
            disabled:opacity-50 disabled:pointer-events-none flex-auto flex justify-center"
            @click="addNewObservation">
            Add New
          </button>
        </div>
      </div>
    </div>
    <!-- <ObservEditModal modalName="observation-edit-modal" :version="selectedObservationVersion"
      :observation="selectedObservation" :selectedSignal="selectedSignal" /> -->
    <WeeklyChildEditModal modalName="weekly-child-edit-modal" :version="1"
      :observation="selectedObservation" :selectedSignal="selectedSignal" />
  </Card>
</template>

<script setup>
  import { HSOverlay } from 'preline/preline';
  import Card from '@/components/ui/Card.vue';
  import ParentObservEditModal from '@/components/observations/edit/Parent/ParentObservEditModal.vue';
  import FormConfirmModal from '@/components/observations/forms/FormConfirmModal.vue';
  import { computed, ref, watch, inject } from 'vue';
  import { cn } from '@/components/ui/utils'
  import WeeklyChildModal from './forms/WeeklyChild/WeeklyChildModal.vue';
  import WeeklyChildObservViewCard from '@/components/observations/view/WeeklyChildObservViewCard.vue';
import WeeklyChildEditModal from './edit/WeeklyChild/WeeklyChildEditModal.vue';

  const props = defineProps([
    'userData', 'observTab', 'observeData',
    'reloadObservations'
  ])

  const cardRef = ref(null)

  watch(() => props.observTab, () => {
    if (props.observTab === 'weekly-child') {
      cardRef.value.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  })

  // view all route - changed to all
  // const viewAllEducator = computed(() => {
  //   if (props.userData?.is_educator)
  //     return props.userData.educator_id
  //   return 'all'
  // })

  const userDetails = inject('userDetails')

  const selectedObservation = ref(null)
  const selectedObservationVersion = ref(2)

  const selectedSignal = ref(false)

  const selectObservation = (observationData, version = 1) => {
    selectedObservation.value = observationData
    selectedObservationVersion.value = version
    selectedSignal.value = !selectedSignal.value
    HSOverlay.open('#hs-weekly-child-edit-modal')
  }

  const childModalActive = ref(true)
  const reloadChildModal = () => {
    console.log('Reloading Child observation modal')
    childModalActive.value = false
    setTimeout(() => childModalActive.value = true, 100)
  }

  const addNewObservation = () => {
    HSOverlay.open('#hs-weekly-child-form-modal')
  }
</script>