<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] overflow-auto">
        <!-- Cross button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <!-- Content container -->
        <div class="w-full flex flex-col px-4">
          <div class="font-semibold text-[22px] mt-2 mb-6 px-2 md:px-0">
            Add new Educator
          </div>
          
          <!-- Form -->
          <form @submit.prevent="createNewEducator" class="mb-6 px-1">
            <!-- Name -->
            <div class="mb-7">
              <label for="educatorName" class="block text-sm font-medium">Name</label>
              <input v-model="educatorName" type="text" id="educatorName" required class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
  
            <!-- Email (Read-only for display) -->
            <div class="mb-6">
              <label for="email" class="block text-sm font-medium">Email </label> 
              <input type="text" id="email" required v-model="email" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
             
  
            <!-- Phone Number -->
            <div class="mb-6">
              <label for="phoneNumber" class="block text-sm font-medium">Phone Number</label>
  
              <div class="relative mt-1.5">  
              <input v-model="phoneNumber" id="phoneNumber" type="text" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            
              </div>
              <div v-if="isNumberInvalid" class="text-red-500 text-sm mt-1">Please enter a valid 10-digit phone number.</div>
            </div>
      
  
            <!-- Role -->
           <!-- Multi-Select Role Dropdown -->
            <div class="mb-6">
              <label for="roleSelect" class="block text-sm font-medium">Role</label>
              <div class="relative mt-1.5 cursor-pointer border border-gray-300 rounded">
                <select  v-model="selectedRoles"  id="roleSelect"  ref="roleSelect" :key="roleSelectKey" multiple data-hs-select='{
                "placeholder": "Role",
                "toggleTag": "<button type=\"button\"></button>",
                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                 }' 
                  class="hidden">
                  <option v-for="role in availableRoles" :key="role" :value="role">
                    {{ role }}
                  </option>
                </select>
                <div class="absolute top-1/2 end-3 -translate-y-1/2">
                  <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path d="m7 15 5 5 5-5" />
                    <path d="m7 9 5-5 5 5" />
                  </svg>
                </div>
              </div>
            </div>
  
            <!-- Buttons -->
            <div class="flex justify-between mt-8">
              <button type="button" @click="resetChanges" class="bg-white text-blue-600 py-2.5 px-1 w-[135px] text-[14px] text-start rounded-lg hover:text-gray-500 cursor-pointer">Reset changes</button>
              <button type="submit" :disabled="isLoading" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">
                <span v-if="isLoading">Saving...</span>
                <span v-else>Save</span>
              </button>
            </div>

            <div v-if="isLoading" class="text-green-500 text-sm mt-4">Creating new Educator</div>
            <div v-if="errorMessage" class="text-red-500 text-sm mt-4">{{ errorMessage }}</div>


          </form>

        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, inject, defineEmits, defineProps, computed, onMounted,nextTick  } from 'vue';
  import { HSSelect } from 'preline/preline';
  
  
  const isLoading = ref(false);
  const errorMessage = ref(null);  // To store error messages


  const roleSelectKey = ref(0);

  const availableRoles = ref([]);
  const selectedRoles = ref(['Educator']);
  
  // Fetch all roles and initialize Preline select on mount
  onMounted(async () => {
    
    // Fetch roles from the API
    try {
      const response = await $call('curriculum_planner.curriculum_planner.common.educator.api.get_list_of_all_the_roles');
      availableRoles.value = response.roles || []; // Assuming roles come in a 'roles' field
      console.log("Roles list fetch response",response)
      
      setTimeout(() => {
        window.HSStaticMethods.autoInit(); // Initialize custom selects
  
        const roleSelectElement = window.HSSelect.getInstance('#roleSelect');
        if (roleSelectElement) {
          roleSelectElement.on('change', (value) => {
            selectedRoles.value = value ? [...value] : [];
          });
          console.log("selected value changed",selectedRoles.value)
        } else {
          console.error('RoleSelect instance not found');
        }
      }, 100);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  });
  
  
  const emit = defineEmits(['close','upload-success']);
  const $call = inject('$call');
  
  // Initialize form fields with props data
  const educatorName = ref(null);
  const userRole = ref(null); 
  const phoneNumber = ref(null); 
  const email = ref(null);
  
  // API call to update educator details
  const createNewEducator = async () => {
    isLoading.value = true;
    errorMessage.value = null;
    try {
      const response = await $call('curriculum_planner.curriculum_planner.common.educator.api.create_new_educator', {
        new_name: educatorName.value,
        new_roles: selectedRoles.value,
        phone_number: phoneNumber.value,
        user_email: email.value
      });
  
      if (response.status === 'success') {
        emit('upload-success')
        emit('close');
      } else {
        errorMessage.value = response.message || 'An error occurred while creating the Educator.';
      }
    } catch (error) {
      if (error.message.includes('Duplicate entry')) {
        errorMessage.value = 'A user with this email already exists. Please use a different email.';
      } else {
        errorMessage.value = 'An error occurred while creating the Educator.';
      }
      console.error('Error creating Educator:', error);
    }
   finally {
    isLoading.value = false;
   }
  };
  
  
  const isNumberInvalid = computed(() => {
    if (!phoneNumber.value) return false; // If phoneNumber is empty, consider it valid initially.
  
    // Allow optional + followed by 1-3 digits for country code, then 10 digits
    const phonePattern = /^\+?[0-9]{1,3}[0-9]{10}$/;
    return !phonePattern.test(phoneNumber.value);
  });
  
  
  // Reset the form to original values
  const resetChanges = () => {
    educatorName.value = null;
    email.value = null;
    selectedRoles.value = ['Educator']
    phoneNumber.value = null;
    reinitializePrelineSelect();
  };
  
  
  const selectKey = ref(0)
  
  
  const reinitializePrelineSelect = async () => {
    console.log("Reinitializing Preline selects...");

    // Reinitialize Role select
    const roleSelect = window.HSSelect.getInstance(`#roleSelect`);
    if (roleSelect) {
      roleSelect.destroy();
    }
    roleSelectKey.value++;

    await nextTick();
    window.HSStaticMethods.autoInit();
  }; 
  
 
  
  
  
  // Close the component
  const closeComponent = () => {
    emit('close');
  };
  </script>
  
  
  
  
  <style scoped>
  /* Styling for the modal and form */
  .bg-conic-gradient {
    background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
  }
  
  .spinner-mask {
    --spinner-thickness: 3px;
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  }
  </style>
  