<template>
  <div class="hs-dropdown relative inline-flex">
    <button id="hs-dropdown-new-observ" type="button" 
        :class="cn(`hs-dropdown-toggle md:py-3 md:px-4 inline-flex items-center gap-x-2 text-sm font-medium 
        rounded-full md:rounded-lg border 
        border-gray-200 bg-gray-800 text-white shadow-sm hover:bg-gray-900 focus:outline-none 
        focus:bg-gray-900 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 
        dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700
        size-10 md:w-auto md:h-auto justify-center items-center`, isSmall && `md:rounded-full
        md:py-2 md:px-2 md:size-12`)"
        aria-haspopup="menu" aria-expanded="false" aria-label="Dropdown">
        <span :class="cn(`hidden md:inline`, isSmall && 'md:hidden')">Add New Observation</span>
        <span :class="cn(`inline md:hidden text-3xl font-extralight`, isSmall && 'md:inline')">+</span>
    </button>

    <div class="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-60 bg-white shadow-md rounded-lg mt-2 dark:bg-neutral-800 dark:border dark:border-neutral-700 dark:divide-neutral-700 after:h-4 after:absolute after:-bottom-4 after:start-0 after:w-full before:h-4 before:absolute before:-top-4 before:start-0 before:w-full" role="menu" aria-orientation="vertical" aria-labelledby="hs-dropdown-default">
      <div class="p-1 space-y-0.5">
        <button class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 
          focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 
          dark:hover:text-neutral-300 dark:focus:bg-neutral-700 w-full"
          @click="() => openObservationForm('daily-form-modal', 'child')"
          >
          Individual Observation
        </button>
        <button class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 
          focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 
          dark:hover:text-neutral-300 dark:focus:bg-neutral-700 w-full"
          @click="() => openObservationForm('activity-form-modal', 'group')">
          Group/Activity Observation
        </button>
        <button class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 
          focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 
          dark:hover:text-neutral-300 dark:focus:bg-neutral-700 w-full"
          @click="() => openObservationForm('unstruct-form-modal', 'weekly')">
          Weekly Observation
        </button>
        <button class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 
          focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 
          dark:hover:text-neutral-300 dark:focus:bg-neutral-700 w-full"
          @click="() => openObservationForm('weekly-child-form-modal', 'weekly-child')">
          Weekly Child Observation
        </button>
      </div>
    </div>
  </div>

  <DailyObservationModal v-if="childModalActive" modalName="daily-form-modal" :userData="userDetails"
    :reloadFn="reloadChildModal" :reloadObservations="props.reloadObservations" confirmModalName="observations" />
  <ActivityObservationModal v-if="childModalActive" modalName="activity-form-modal" :userData="userDetails" 
    :reloadFn="reloadChildModal" :reloadObservations="props.reloadObservations" confirmModalName="observations" />
  <UnstructObservationModal modalName="unstruct-form-modal" :userData="props.userData"
    :reloadObservations="props.reloadObservations" confirmModalName="observations" />
  <WeeklyChildModal v-if="childModalActive" modalName="weekly-child-form-modal" 
    :userData="userDetails" confirmModalName="observations" :reloadObservations=props.reloadObservations />
  <FormConfirmModal :createAnotherFn="addAnotherFn" modalName="observations" :formType="currentObservType" />
    <!-- <template #trigger>
      <button data-hs-overlay="#hs-form-confirm-modal-observations">COndsfjsdi modal</button>
    </template> -->
</template>

<script setup>
import DailyObservationModal from '@/components/observations/forms/DailyObservationModal.vue';
import WeeklyChildModal from '@/components/observations/forms/WeeklyChild/WeeklyChildModal.vue';
import ActivityObservationModal from '@/components/observations/forms/ActivityObservationModal.vue'
import UnstructObservationModal from '@/components/observations/forms/UnstructObservationModal.vue'
import { inject, onMounted, onUnmounted, ref } from 'vue';
import { cn } from '@/components/ui/utils.js';
import { HSOverlay } from 'preline';
import FormConfirmModal from '@/components/observations/forms/FormConfirmModal.vue';

const props = defineProps([
  'reloadObservations', 'isSmall'
])

const userDetails = inject('userDetails')

const childModalActive = ref(true)
const reloadChildModal = () => {
  console.log('Reloading Child observation modal')
  childModalActive.value = false
  setTimeout(() => childModalActive.value = true, 100)
}

const currentModalName = ref('')
const currentObservType = ref('')

const openObservationForm = (modalName, observType) => {
  currentModalName.value = modalName;
  currentObservType.value = observType;

  HSOverlay.open(`#hs-${modalName}`)
}

const addAnotherFn = () => {
  HSOverlay.open(`#hs-${currentModalName.value}`)
}
</script>