<template>
  <div class="flex flex-col max-h-[94vh] md:max-h-[91.5vh] pt-6 pb-20 md:pb-6 lg:pb-8 px-2 lg:py-8 lg:px-16 
    gap-4 lg:gap-8 lg:pr-28 overflow-y-auto">
    <h2 class="text-3xl font-normal">
      Observation Summaries
    </h2>
    <div class="flex md:flex-row gap-2 md:gap-8 md:items-center">
      <AddNewDropdown :isSmall="true" />
      <router-link to="/front-portal/observations">
        <button class="py-3 px-4 inline-flex items-center gap-x-2 text-xs
          md:text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          <span class="hidden md:inline">View </span>Observations
        </button>
      </router-link>
      <router-link to="/front-portal/observations/statistics">
        <button class="py-3 px-4 inline-flex items-center gap-x-2 text-xs
          md:text-sm font-semibold rounded-md border border-transparent bg-[#FDD835] text-gray-800
          hover:bg-[#f7cd25] disabled:opacity-50 disabled:pointer-events-none sm:w-48 md:w-fit"
          type="button">
          Statistics
        </button>
      </router-link>
    </div>
    <div class="flex flex-col lg:flex-row items-start lg:items-center gap-2 lg:gap-8">
      <h3 class="text-xl font-medium">
        This Week at a Glance
      </h3>
            <div class="flex">
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-l-lg border border-transparent bg-[#FDD835] text-black hover:bg-[#fef3c2]
                focus:outline-none focus:bg-[#fef3c2] disabled:opacity-50 disabled:pointer-events-none"
                @click="() => { changeWeek(-1) }"
                >
                <span><</span>
              </button>
              <div class="bg-[#FDD835] text-gray-900 px-8 flex justify-center items-center font-medium
                text-xs lg:text-sm py-3">
                {{ formatDateWithSuffixShort(weekdays[0]) }} - {{ formatDateWithSuffixShort(weekdays[4]) }}
              </div>
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-r-lg border border-transparent bg-[#FDD835] text-black hover:bg-[#fef3c2]
                focus:outline-none focus:bg-[#fef3c2] disabled:opacity-50 disabled:pointer-events-none
                flex gap-2"
                @click="() => { changeWeek(1) }"
                >
                <span>></span>
              </button>
            </div>
        <div v-if="userDetails?.is_system_manager" class="flex gap-4">
          <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
            rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
            disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
            dark:text-white dark:hover:bg-neutral-800" @click="generateSummariesForWeek" >
            Generate All
          </button>
          <GenerateModal modalName="generate-weekly-overview-modal" :today="today" :reloadData="reloadData">
            <template #trigger>
              <button type="button" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700
                dark:text-white dark:hover:bg-neutral-800":data-hs-overlay="`#hs-generate-weekly-overview-modal`">
                Generate Overview
              </button>
            </template>
          </GenerateModal>
        </div>
    </div>
    <div v-if="isGenerating">
      <p class="text-gray-500 font-medium">
        Generating summaries, please wait...
      </p>
    </div>
    <div class="grid md:grid-cols-3 lg:grid-cols-11 w-full max-w-full gap-8 items-start">
      <Card class="lg:col-span-11">
        <div class="flex flex-col gap-4">
          <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
            Overview of the week
          </h5>
          <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3 flex-auto">
            <!-- <p class="font-medium">Summary & suggestions:</p> -->
            <div class="text-wrap">
              {{ weeklyDataResource.data?.summaries?.overview?.summary || "Summary not found." }}
            </div>
          </div>
        </div>
      </Card>
      <Card class="lg:col-span-6 lg:row-span-5">
        <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
          Most and Least Engaging Activities
        </h5>
        <EngagingGraphs :activityData="activityData" />
        <div class="flex flex-col md:flex-row gap-2">
          <div class="flex-auto basis-1/3 flex p-3 md:p-0 md:justify-center">
            <div class="space-y-1">
              <div class="flex gap-2" v-for="(comp, index) in competencies">
                <div :style="{ backgroundColor: colors[index] }" :class="`size-4 shrink-0`" ></div>
                <p class="text-[0.6rem] font-medium">{{ comp }}</p>
              </div>
            </div>
          </div>
          <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3 flex-auto basis-2/3">
            <p class="font-medium">Summary & suggestions:</p>
            <div class="text-wrap">
              {{ weeklyDataResource.data?.summaries?.engaging_activities?.summary || "Summary not found." }}
            </div>
          </div>
        </div>
      </Card>
      <!-- Photos from the week begins here-->
      <!-- Either remove the code below and add your own or keep the below code and add functionality to view the phone
          If removing and adding, maintain the same styling -->
      <!-- <Card class="lg:col-span-5 lg:row-span-2">
        <div class="flex flex-col gap-3 md:flex-row">
          <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
            Photos from the Week
          </h5>
          <div>Preview thumbnails</div>
        </div>
      </Card> -->
      <!-- Photos from the week ends here-->
      <Card class="lg:col-span-5 space-y-3 lg:row-span-3">
        <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
          Competencies Linked to Most Activities
        </h5>
        <MostLinkedGraph :activityData="activityData" />
        <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3">
          <p class="font-medium">Summary & suggestions:</p>
          <div class="">
            {{ weeklyDataResource.data?.summaries?.competencies_linked_to_most_activities?.summary || "Summary not found." }}
          </div>
        </div>
      </Card>
      <Card class="lg:col-span-5 space-y-3">
        <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
          Weekly Changes Overview
        </h5>
        <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3">
          <p class="font-medium">Summary & suggestions:</p>
          <div class="">
            {{ weeklyDataResource.data?.summaries?.weekly_changes?.summary || "Summary not found." }}
          </div>
        </div>
      </Card>
      <Card class="lg:col-span-6 space-y-3">
        <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
          Ability to Focus on Tasks
        </h5>
        <FocusGraph :weeklyChildData="weeklyChildData" />
        <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3">
          <p class="font-medium">Summary & suggestions:</p>
          <div class="">
            {{ weeklyDataResource.data?.summaries?.ability_to_focus?.summary || "Summary not found." }}
          </div>
        </div>
      </Card>
      <Card class="lg:col-span-6">
        <div class="space-y-6">
          <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
            Behavior During Free Play Time
          </h5>
          <FreeplayGraph :weeklyChildData="weeklyChildData" />
          <!-- <MostLinkedGraph :activityData="activityData" /> -->
          <div class="rounded bg-[#FFF9E5] whitespace-pre-line text-sm p-3">
            <p class="font-medium">Summary & suggestions:</p>
            <div class="">
              {{ weeklyDataResource.data?.summaries?.free_play_behavior?.summary || "Summary not found." }}
            </div>
          </div>
        </div>
      </Card>
      <Card class="lg:col-span-5 w-full overflow-x-auto" 
        v-if="weeklyDataResource.data?.graph_data?.activity?.hit_activities?.length">
        <div class="space-y-6">
          <h5 class="underline font-medium underline-offset-[0.4rem] decoration-2">
            Activities that were the Biggest Hits
          </h5>
        </div>
        <div class="w-full overflow-x-auto">
          <table v-if="weeklyDataResource.data?.graph_data?.activity?.hit_activities"
            class="border-collapse rounded-lg w-full overflow-x-auto">
            <tr class="font-medium text-xs 2xl:text-[0.8rem] text-gray-900 rounded-lg">
              <th class="py-3 text-left px-1 pl-2 xl:pl-4">
                Cohort
              </th>
              <th class="py-3 text-left px-1">
                Activity Name
              </th>
              <th class="py-3 text-left px-1">
                Competency
              </th>
              <th class="py-3 text-left px-1">
                Facilitator
              </th>
              <th class="py-3 text-left px-1">
                Date
              </th>
            </tr>
            <tr v-for="event in weeklyDataResource.data.graph_data.activity.hit_activities.slice(0, 5)"
              class="whitespace-nowrap hover:bg-[#FEF3C2] cursor-pointer
              text-[0.4rem] md:text-[0.5rem] 2xl:text-[0.8rem]"
              @click="() => {}">
              <td class="px-1 py-3">
                {{ findValueByName(userDetails?.school_info.cohorts, event.curriculum_event.cohort[0].link_to_cohort, 'cohort_name') }}
              </td>
              <td class="px-1 py-3 pl-2 xl:pl-4">
                {{ (event.curriculum_event.activity_name || event.curriculum_event.name1 || 'Unnamed Activity') }}
              </td>
              <td class="px-1 py-3 pl-2 xl:pl-4">
                <span class="rounded bg-[#FDD835] p-1">
                  {{ event.curriculum_event.custom_competency[0].link_to_competency }}
                </span>
              </td>
              <td class="px-1 py-3">
                {{ findValueByName(userDetails?.school_info.educators, event.curriculum_event.led_by[0].link_to_educator, 'educator_name') }}
              </td>
              <td class="px-1 py-3 pl-2 xl:pl-4">
                <span class="rounded bg-[#FDD835] p-1">
                  {{ formatDateWithSuffixShort(event.curriculum_event.start) }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </Card>
    </div>
  </div>
</template>

<script setup>
import AddNewDropdown from '@/components/observations/forms/AddNewDropdown.vue';
import { createResource } from 'frappe-ui'
import Card from '../../../ui/Card.vue';
import { computed, inject, ref } from 'vue';
import EngagingGraphs from './EngagingGraphs.vue';
import MostLinkedGraph from './MostLinkedGraph.vue';
import FreeplayGraph from './FreeplayGraph.vue';
import FocusGraph from './FocusGraph.vue';
import { findValueByName } from '@/lib/utils';
import { 
  getTodayDateJSFormat, getWeekdays, getNextWeekDate, 
  formatDateWithSuffixShort, getLastWeekDate
} from '@/lib/utils';
import GenerateModal from './GenerateModal.vue';

const userDetails = inject('userDetails')

const today = ref(getTodayDateJSFormat())
console.log("Summary today", today)
const weekdays = computed(() => getWeekdays(today.value))
console.log("Summary weekdays", weekdays)

const weeklyDataResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.weekly.api.fetch_ongoing_data',
 params: {
  date: today.value,
 },
 auto: true,
})

const activityData = computed(() => {
  return weeklyDataResource.data?.graph_data?.activity || null;
})

const weeklyChildData = computed(() => {
  return weeklyDataResource.data?.graph_data?.weekly_child || null;
})

const changeWeek = (difference) => {
  if (difference === 1) {
    const newToday = getNextWeekDate(today.value);
    today.value = newToday;
    weeklyDataResource.submit({ date: newToday })
  } else if (difference === -1) {
    const newToday = getLastWeekDate(today.value);
    today.value = newToday;
    weeklyDataResource.submit({ date: newToday })
  }
  console.log(weeklyDataResource)
}

console.log("ACtivity data", activityData)
console.log("Weekly^2 child data", weeklyChildData)

const competencies = [
  "Storytelling",
  "Critical Thinking",
  "Emotional Intelligence",
  "Fine Motor Skills",
  "Math & Numeracy",
  "Physical Wellness",
  "Reading & Writing",
  "Science Exploration",
  "Music & Rhythm",
  "Visual Arts",
  "Others",
]

const colors = ['#F5CCE8', '#9A79A6', '#7B2D26', '#FB4D3D', '#F18D01', '#FFC000', '#FFEBAE', '#8DB580', '#D3FCD5', '#70E4EF', '#345995']

const generateWeeklySummariesResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.weekly.api.generate_ongoing_summaries'
})

console.log("weekly", generateWeeklySummariesResource)

const isGenerating = ref(false)
const generateSummariesForWeek = async () => {
  try {
    console.log('date', today.value)
    isGenerating.value = true;
    await generateWeeklySummariesResource.submit({
      date: today.value,
    })
    if (generateWeeklySummariesResource.data?.status === 'success') {
      weeklyDataResource.submit({ date: today.value })
    }
  } catch (error) {
    console.error("Failed to make generate request", error)
  }
  isGenerating.value = false;
}

const reloadData = () => {
  weeklyDataResource.submit({ date: today.value })
}
</script>