<template>
  <Card @click="viewObservation" :class="cn('transition-all cursor-pointer hover:brightness-95 rounded', props.class)">
    <template #title>
      <p class="font-semibold text-base">
        {{ props.observation.cohorts[0].cohort_name }}
        <span v-if="props.observation.cohorts?.length > 1">
          + {{ props.observation.cohorts?.length - 1 }} more
        </span>
        <!-- <div v-for="cohort in props.observation.cohorts.slice(0, 3)"   
          class="bg-[#FDD835] px-2 rounded items-center justify-center py-0">
          {{ cohort.cohort_name }}
        </div> -->
        <!-- <span class="flex gap-2 items-center">
          <span>{{ getFormattedDate(props.observation.date) }}</span>
          <span>-</span>
          <span>
            {{ truncateWithEllipsis(findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name'), 8) }}
          </span>
          <span v-if="props.observation.is_archived" class="text-sm text-gray-600">(archived)</span>
        </span> -->
      </p>
    </template>
    <div class="flex flex-col gap-4">
      <div class="flex justify-between">
        <div class="text-sm text-gray-600 flex gap-1 items-center">
          <span class="font-medium">{{ props.observation.date }}</span>
          <span class="text-lg">|</span>
          <span class="font-medium">
            {{ truncateWithEllipsis(findValueByName(userDetails?.school_info.educators, props.observation.educator_name, 'educator_name'), 8) }}
          </span>
        </div>
        <div v-if="props.observation.is_imported" class="text-xs text-gray-600">
          imported
        </div>
      </div>
      <div class="flex gap-2 flex-wrap">
        <div 
          class="bg-[#FDD835] px-2 rounded items-center justify-center py-0">
          {{ findValueByName( competencies, props.observation.primary_competency, 'competency_name') }}
        </div>
        <!-- <div v-for="cohort in props.observation.cohorts.slice(0, 3)"   
          class="bg-[#FDD835] px-2 rounded items-center justify-center py-0">
          {{ cohort.cohort_name }}
        </div> -->
        <!-- <div v-if="props.observation?.cohorts.length > 3" class="text-[#FDD835] font-bold text-3xl h-8">
          ...
        </div> -->
      </div>
      <p class="text-ellipsis line-clamp-2 font-light text-gray-700 text-sm xl:text-base">
        {{ props.observation.activity_summary }}
      </p>
    </div>
  </Card>
</template>

<script setup>
import Card from '@/components/ui/Card.vue'
import { truncateWithEllipsis, findValueByName, getFormattedDate } from '@/lib/utils'
import { HSOverlay } from 'preline/preline';
import { cn } from '@/components/ui/utils.js'
import { inject } from 'vue';

const props = defineProps([
  'observation', 'selectObservation', 'version', 
  'class', 'modalName'
])

const userDetails = inject('userDetails')
const competencies = inject('observationCompetencies')

const viewObservation = () => {
  props.selectObservation(props.observation, props.version)
  if (props.modalName) {
    HSOverlay.open(`#hs-${props.modalName}`)
  } else {
    HSOverlay.open('#hs-observation-edit-modal-all')
  }
}
</script>